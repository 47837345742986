/* Google Font Link */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.topbar {
  height: 93px;
}

.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  width: 80px !important;
  height: 100vh;
  padding: 3px 10px;
  z-index: 990;
  border-right-style: solid;
  border-color: #eee;
  border-width: 1px;
  transition: all 0.3s ease;
  background-color: white;
}

.sidebar i {
  color: #fff;
  height: 60px;
  min-width: 50px;
  text-align: center;
  line-height: 60px;
}

.sidebar ul {
  overflow: visible;
}

.sidebar .nav-list {
  white-space: nowrap;
  margin-top: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sidebar li {
  position: relative;
  margin: 8px 0;
  list-style: none;
}

.sidebar li .tooltip {
  position: absolute;
  top: -20px;
  right: 3.5rem;
  z-index: 3;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 400;
  opacity: 0;
  pointer-events: none;
  transition: 0s;
}

.sidebar li:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  transition: all 0.3s ease;
  top: 50%;
  transform: translate(130%, -50%);
}

.sidebar.open li .tooltip {
  display: none;
}

.butn {
  padding: 1px !important;
  border-radius: 50%;
  margin-top: 10px;
}

.activeButton {
  margin: 10px 10px;
  transition: all 0.3s;
  border-radius: 10px;
  padding: 2px;
}

.sidebar.active {
  width: 260px !important;
  transition: all 0.3s;
  transform: translateX(0%) !important;
}

.sidebar li a {
  display: flex;
  height: 100%;
  width: 50%;
  border-radius: 12px;
  align-items: center;
  text-decoration: none;
  transition: all 0.3s ease;
  color: #5d5d5d;
  font-weight: 500;
  margin-left: 7px;
  float: left;
}

.sidebar li a:hover {
  color: rgb(0, 0, 0);
}

.sidebar li a:hover .links_name,
.sidebar li a:hover i {
  transition: all 0.3s ease;
  color: #11101D;
}

.sidebar li i {
  height: 50px;
  padding-right: 12px;
  line-height: 50px;
  border-radius: 12px;
  color: rgb(91, 91, 91);
  margin-right: 10px;
}

li .activeItem {
  float: right;
  width: 7px;
  border-right-style: solid;
  border-radius: 7px 0 0 7px;
  border-width: 7px;
  color: #3c7674;
  transform: translate(11px);
}

li .itemIndicator {
  position: relative;
  width: 7px;
  height: 100%;
}

.sidebar li .tooltip{
  position: absolute;
  z-index: 900;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 400;
  opacity: 0;
  pointer-events: none;
  transition: 0s;
}

.sidebar .active-icon {
  color: #3c7674;
}

.sidebar .active-font {
  color: #3c7674;
  font-weight: 700;
}

.arrowRight i {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  /* background-color: #c3c3c3;*/
  color: rgb(138, 7, 7);
  margin: 0px 15px 0px 0px;
  padding: 0;
  font-size: 55px;
  position: absolute;
  top: 10px;
  cursor: pointer;
  animation: rote 2s;
  animation: infin 1s infinite;
}


.arrowLeft i {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  /* background-color: #c3c3c3;*/
  color: rgb(138, 7, 7);
  margin: 0px 15px 0px 0px;
  font-size: 55px;
  transform: rotate(180deg);
  position: absolute;

  top: 12px;
  cursor: pointer;
  animation: rote 2s;
  animation: infin 1s infinite;

}


@keyframes infin {
  0% {
    right: 210px;
  }

  50% {
    right: 213px;
  }

  100% {
    right: 210px;
  }
}

#navItem {
  font-size: 1.4rem;
}

.navLogo {
  height: 3.5rem;
  min-height: 3.5rem;
}

.topBar {
  padding-left: 80px;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: start;
  align-items: center;
  align-content: stretch;
  transition: all 0.3s;
  border-bottom-style: solid;
  border-color: #eee;
  border-width: 1px;
}

.topBar.active {
  padding-left: 260px !important;
}

.topBar i {
  font-size: 1.4rem;
}

/* .topBar .searchBar {
  width: 30em;
  margin-left: 2.6rem;
} */

.patient-searchbar{
  width: 366px;
  height: 43px;
  margin: 3px 645.5px 0 28px;
  padding: 13px 23px 11px 22px;
  border-radius: 50px;
  box-shadow: 30px 70px 120px 0 rgba(27, 49, 66, 0.13);
  border: solid 1px #f3f3f3;
  background-color: #fff;
}

.topBar-username {
  font-family: Poppins;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5b5d5d;
}

.topBar-edit-profile {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}

.topBar-profile-box {
  min-width: 10%;
  border-left-style: solid;
  border-width: 1px;
  border-color: #ccc;
} 

.navbar-margin {
  margin-left: 80px;
}

@media screen and (max-width: 770px) {
  .topBar {
    padding-left: 0px;
  }

  .sidebar {
    transform: translateX(-100%);
  }
  .navbar-margin {
    margin-left: 0px;
  }
}