/* SECTION'S TEXT INPUT */
.section-title {
  font-weight: bolder;
}

.section-text {
  border-radius: 8px;
  border-color: #ddd;
  box-shadow: 0 4px 30px 0 rgba(104, 104, 104, 0.1);
  background-color: #fff;
}

.section-text:focus-visible{
  outline: 2px solid #2684ff;
}

/* DELETE SECTION BUTTON */
.bt-delete {
  float: right;
  margin-bottom: 1rem;
  color: rgb(105, 105, 105);
  background-color: rgb(255, 255, 255);
  min-width: 60px;
  -webkit-box-shadow: 2px 3px 10px #999;
  -moz-box-shadow: 2px 3px 10px #999;
  font-size: 1rem;
  border-radius: 8px;
  width: 12rem;
  height: 35px;
}

.bt-delete:hover:enabled {
  color: rgb(184, 0, 0);
  -webkit-transition: background-color 100ms ease-out;
  -moz-transition: background-color 100ms ease-out;
  -o-transition: background-color 100ms ease-out;
  transition: background-color 100ms ease-out;
  -webkit-box-shadow: inset -31px -32px 24px -25px rgba(63, 63, 63, 0.12),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
  box-shadow: inset -31px -32px 24px -25px rgba(63, 63, 63, 0.12),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
}

.bt-delete:hover:enabled i {
  transition: 350ms;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

/* ADD SECTION BUTTON */

.bt-section {
  color: rgb(105, 105, 105);
  background-color: rgb(255, 255, 255);
  border-color: #fff;
  min-width: 60px;
  border-radius: 8px;
  -webkit-box-shadow: 2px 3px 10px #999;
  -moz-box-shadow: 2px 3px 10px #999;
}

.bt-section:hover:enabled {
  color: rgb(105, 105, 105);
  background-color: #fff;
  -webkit-transition: background-color 100ms ease-out;
  -moz-transition: background-color 100ms ease-out;
  -o-transition: background-color 100ms ease-out;
  transition: background-color 100ms ease-out;
  -webkit-box-shadow: inset -31px -32px 24px -25px rgba(63, 63, 63, 0.12),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
  box-shadow: inset -31px -32px 24px -25px rgba(63, 63, 63, 0.12),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
}

.bt-section:hover:enabled i {
  transition: 350ms;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.conclusion-list-item {
  border-radius: 21.5px;
  border: solid 1px rgba(124, 174, 173, 0.24);
  background-color: rgba(124, 174, 173, 0.24);
  font-size: 12px;
}

.add-conclusion-btn {
  cursor: pointer;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #22654e;
  text-decoration: underline;
}

.conclusion-modal-title {
  font-family: Poppins;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  text-align: left;
  color: #22654e;
}

.no-conclusions-sign {
  padding: 10% 0 20%;
  margin-bottom: 20%;
  border-radius: 16px;
  background-image: linear-gradient(to bottom, #3c767411 0%, #ffffff11 100%);
}

.no-conclusions-sign i {
  font-size: 68px;
  color: #22654e;
}

.no-conclusions-sign .no-conclusions-text {
  font-family: Poppins;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: center;
  color: #22654e;
}