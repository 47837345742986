
.left {
  text-align: center;
  display: flex;
  justify-content: center;
}

.logos {
  margin: 0px auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.landinglogo {
  max-width: 90%;
  width: 600px;
}
.landingiso {
  max-width: 90%;
  width: 210px;
}

.logo-vpsy {
  width: 20%;
}
.linkto {
  color: dimgray;
  text-align: center;
}

.linkto a {
  color: dimgray;
  text-align: center;
}

.subs-container {
  height: 85vh;
  /* padding-bottom: 10vh; */
}
.subs-container > h1 {
  text-align: center;
  /*font-family: 'Yusei Magic', sans-serif;
  font-size: 30px;
  margin-top:10px;*/
}

.about-container {
  height: 75vh;
}

h1 {
  /* font-family: 'Big Shoulders Stencil Text', cursive; */
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  /* margin-top: 2rem; */
  font-size: 3rem;
  color: rgba(82, 82, 82, 0.829);
}
.about-container > h1 {
  text-align: center;
  padding: 0;
  margin: auto;
  /*font-family: 'Yusei Magic', sans-serif;
    font-size: 30px;
    margin-top:10px;*/
}

.about-info {
  width: 90%;
  height: 65%;
  margin: auto;
  margin-top: 7rem;
}

.arrow {
  text-align: center;
  justify-content: center;
  /*position: absolute; El div será ubicado con relación a la pantalla*/
  left: 0px; /*A la derecha deje un espacio de 0px*/
  right: 0px; /*A la izquierda deje un espacio de 0px*/
  bottom: 15px; /*Abajo deje un espacio de 0px*/

  z-index: 0;
}

.info {
  width: 90%;
  margin: 5vh auto;
}

.infoText {
  line-height: 1.5;
  font-size: 1.2rem;
  width: 80%;
  margin: 1.5rem auto;
  /* font-family: 'Jost', sans-serif; */
  font-family: 'Source Sans Pro', sans-serif;
}

.icons i {
  margin: 3px auto;
  font-size: 2rem;
  color: rgb(138, 138, 138);
}

.cardStyles {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  height: 100%;
  font-size: 1rem;
  margin-top: 1rem;
}

.cardStyles:hover:enabled {
  box-shadow: rgba(0, 0, 0, 0.123) 0px 1px 2px, rgba(0, 0, 0, 0.219) 0px 2px 4px,
    rgba(0, 0, 0, 0.219) 0px 4px 8px, rgba(0, 0, 0, 0.123) 0px 8px 16px,
    rgba(0, 0, 0, 0.123) 0px 16px 32px, rgba(0, 0, 0, 0.123) 0px 32px 64px;
}

.cardStyles i {
  color: rgba(13, 81, 102, 0.829);
}

.brain {
  margin: 0 auto;
}

.imagen1 {
  height: 50%;
  width: auto;
  position: absolute;
  margin-left: 100px;
  margin-top: 120px;
  z-index: 40;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.imagen2 {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  height: 58%;
  width: auto;
  position: absolute;
  margin-top: -25px;
  margin-left: 115px;
  transform: rotate(8deg);
  z-index: 30;
}
.imagen3 {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  height: 60%;
  width: auto;
  margin-top: -40px;
  position: absolute;
  z-index: 25;
  transform: rotate(-12deg);
  margin-left: -60px;
}

.fadeInRight {
  animation: aparece-derecha ease-in-out 3s backwards;
}

.formLanding {
  margin: 0 auto;
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: rgba(241, 241, 241, 0.918);
  padding: 2rem;
  width: 600;
  height: auto;
  max-width: 450px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}

.formLanding .checkbox {
  font-weight: 300;
}
.formLanding .form-control {
  position: relative;
  box-sizing: border-box;
  height: 30px;
  padding: 10px;
  font-size: 16px;
}
.formLanding .form-control:focus {
  z-index: 2;
}

.formLanding input {
  margin-bottom: -1px;
  border-bottom-left-radius: 0;
  box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  -webkit-box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  -moz-box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  border: none;
}

.formLanding button {
  color: gray;
  margin-top: 20px;
  background-color: rgba(230, 230, 230, 0.808);
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 6px 7px 24px -4px rgba(150, 150, 150, 0.9);
  -webkit-box-shadow: 6px 7px 24px -4px rgba(150, 150, 150, 0.9);
  -moz-box-shadow: 6px 7px 24px -4px rgba(150, 150, 150, 0.9);
  border: none;
}

.formLanding button:hover:enabled {
  margin-top: 20px;
  background-color: rgba(180, 180, 180, 0.6);
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  -webkit-box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  -moz-box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  border: none;
}
.formLanding button:hover:enabled i {
  transition: 350ms;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.formLanding #sign-up {
  color: #FFF;
  margin-top: 20px;
  background-color: #4FB04F;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 6px 7px 24px -4px rgba(150, 150, 150, 0.9);
  -webkit-box-shadow: 6px 7px 24px -4px rgba(150, 150, 150, 0.9);
  -moz-box-shadow: 6px 7px 24px -4px rgba(150, 150, 150, 0.9);
  border: none;
}

.formLanding #sign-up:hover:enabled {
  color: #FFF;
  margin-top: 20px;
  background-color: #3FA03F;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  -webkit-box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  -moz-box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  border: none;
}
.formLanding #sign-up:hover:enabled i {
  transition: 350ms;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.info-adv {
  width: 90%;
  margin: 5% auto;
}

.subs strong {
  color: #22654e;
}

.subs {
  font-size: 1.5rem;
}
.smallInfo {
  font-size: 1rem;
  color: rgb(79, 47, 51);
  /* margin-top: 2rem; */
  background-color: rgba(75, 75, 75, 0.281);
  padding: 10px;
  border-radius: 20px;
}

.redes {
  width: 60%;
  height: 80px;
  display: flex;
  margin: 10px auto;
}

.mercadoPago {
  width: 60px;
  margin: auto;
}

 .images{
   position:relative;
 }

@media (max-width: 500px){
  .about-info {
    margin-top:2px;
  }
  .images{
    position:relative;
    width: 85vw !important;
    height: 80vh;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  .imagen1 {
    width:85% !important;
    height: auto;
     z-index: 40;
     margin-top: 330px!important;
     margin-left: 10px!important;
     
   }
   
   .imagen2 {
     
    width:80% !important;
    height: auto;
    margin-top: 210px !important;
    margin-left: 50px!important;
     transform: rotate(8deg);
     z-index: 30;
   }
   .imagen3 {
     width: 80% !important;
     height: auto;
     margin-top: 10px !important;
     z-index: 25;
     transform: rotate(-12deg);
     margin-left: -20px!important;
  
   }
  .arrow {
    text-align: center;
    justify-content: center;
    position: -webkit-sticky;
position: sticky;
top: 20px;
    
  
    z-index: 50;
  }

  .box {
  margin-bottom: 20px;
  }
  .background{
    visibility: hidden;
  }

  .about-container > h1{
    padding-top: 40px!important;
  }

  .infoText {
    width: 80% !important;
    margin-top:30px !important;

  }
  .redes {
    width: 90%;
    height: 80px;
    display: flex;
    margin: 10px auto;
    margin-top: 30px;
  }
  
  .about-info {
    width: 100% !important;
    height: auto;
    margin: auto;
    margin-top: 2rem;
  }
  

  }



@media (min-width: 501px) and (max-width: 991px){
  
  .box{
    margin-bottom: 20px !important;
  }
  .infoText {
    width: 95% !important;
    margin: 1rem auto;

  }

 
  .images{
   
    width: 85vw !important;
    height: 80vh;
    margin-top: 40px;
    margin-left: 10%;
    margin-right: 10%;
  }

  .imagen1 {
    width:70% !important;
    height: auto;
     z-index: 40;
     margin-top: 200px!important;
     margin-left: 100px!important;
     
   }
   
   .imagen2 {
     
    width:50% !important;
    height: auto;
    margin-top: 20px !important;
     transform: rotate(8deg);
     z-index: 30;
     margin-left: 250px!important;
   }
   .imagen3 {
     width: 50% !important;
     height: auto;
     z-index: 25;
     transform: rotate(-12deg);
     margin-left: 20px!important;
  
   }
  .arrow {
   
    position: sticky; 
    z-index: 40;
  }
}


@media (max-width: 1300px){
  .infoText {
    width: 50%;
    margin: 1rem auto;

  }
  .about-info {
    width: 100% !important;
    height: auto;
    margin: auto;
    margin-top: 2rem;
  }
  
  .images{
   margin-right: 20px !important;
   max-height: 50vh;
  }

  .imagen1 {
   width:80%;
   height: auto;
    position: absolute;
    margin-left: 5px;
    margin-top: 130px;
    z-index: 40;
    
  }
  
  .imagen2 {
    
   width:70%;
   height: auto;
    position: absolute;
    margin-top: -10px;
    margin-left: 100px;
    transform: rotate(8deg);
    z-index: 30;
  }
  .imagen3 {
    width: 75%;
    height: auto;
    margin-top: -20px;
    position: absolute;
    z-index: 25;
    transform: rotate(-12deg);
    margin-left: -90px;
  }

}