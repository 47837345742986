span.page-link {
  cursor: pointer;
}

.page-item.active .page-link {
  z-index: 0;
}

.page-item.active .page-link {
  background-color: rgb(173, 173, 173);
  border-color: rgb(173, 173, 173);
}

.page-item {  
  cursor: pointer;
}

span .page-arrow {
  width: 6px;
  height: 10px;
  flex-grow: 0;  
  cursor: pointer;
}

span .page-text {
  width: 70px;
  height: 18px;
  flex-grow: 0;
  font-family: Poppins;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #5b5d5d;
}