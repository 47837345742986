.subscription-container {
  border-width: 1px 0px;
  border-color: lightgrey;
  border-radius: 5px;
  margin: 4px;
  padding: 5px;
  justify-content: center;
}

.subscription-button {
  color: #FFF;
  background-color: #4FB04F;
  float: right;
  justify-self: center;
}

.subscription-button:hover:enabled {
  color: #FFF;
  background-color: #3FA03F;
}

.subscription-title {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.3rem;
  margin: 0.5rem;
  display: inline-block;
}
.mp{
  width: 100px;
  margin: auto;
}
