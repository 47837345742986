@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap);
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  background-image: linear-gradient(to bottom, #fff, #f1eeef);
}

.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-brand img {
  width: 45px;
}

.user-dropdown {
  width: 25px;
}

.vpsy-font-color {
  color: #22654e;
}

.vpsy-font {
  font-family: Poppins;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
}

.label-vpsy-font {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.16px;
  text-align: left;
  margin-left: 0.88rem;
}

.form-card {
  background-color: white;
  border-radius: 16px;
}

.form-control {
  border-radius: 50px !important;
  box-shadow: 30px 70px 120px 0 rgba(27, 49, 66, 0.13) !important;
  border: solid 1px rgba(201, 201, 201, 0.56) !important;
  background-color: #fff !important;
  font-size: 14px;
  height: 38px;
}

.form-signin {
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 0 50px 50px;
  max-width: 450px;
  position: relative;
  padding-top: 0;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input[name='username'] {
  margin-bottom: -1px;

  border-bottom-left-radius: 0;
  box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  -webkit-box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  -moz-box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  border: none;
}
.form-signin input[type='password'] {
  margin-top: 10px;
  margin-bottom: 10px;

  border-top-right-radius: 0;
  box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  -webkit-box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  -moz-box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  border: none;
}

.form-signin button {
  color: gray;
  margin-top: 20px;
  background-color: rgba(211, 211, 211, 0.6);
  margin-bottom: 10px;
  border-radius: 50px;
  box-shadow: 6px 7px 24px -4px rgba(150, 150, 150, 0.9);
  -webkit-box-shadow: 6px 7px 24px -4px rgba(150, 150, 150, 0.9);
  -moz-box-shadow: 6px 7px 24px -4px rgba(150, 150, 150, 0.9);
  border: none;
}

.form-signin button:hover:enabled {
  color: #000;
  margin-top: 20px;
  background-color: rgba(180, 180, 180, 0.6);
  margin-bottom: 10px;
  box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  -webkit-box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  -moz-box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  border: none;
}
.form-signin button:hover:enabled i {
  transition: 350ms;
  transform: rotate(360deg);
}

.form-signin #sign-up {
  color: #FFF;
  margin-top: 20px;
  background-color: #2097F3;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 6px 7px 24px -4px rgba(150, 150, 150, 0.9);
  -webkit-box-shadow: 6px 7px 24px -4px rgba(150, 150, 150, 0.9);
  -moz-box-shadow: 6px 7px 24px -4px rgba(150, 150, 150, 0.9);
  border: none;
}

.form-signin #sign-up:hover:enabled {
  margin-top: 20px;
  background-color: #1087D3;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  -webkit-box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  -moz-box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  border: none;
}
.form-signin #sign-up:hover:enabled i {
  transition: 350ms;
  transform: rotate(360deg);
}

.logo-login {
  margin: 10px 30px;
}

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  animation-name: fadeInDown;
  animation-duration:300ms;
  animation-fill-mode: both;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

#wrap,
.wrapper {
  min-height: 100%;
}

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 20px;
}

/* Simple CSS3 Fade-in Animation */

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  opacity: 0;
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration:300ms;
}

.fadeIn.first {
  animation-delay: 0.4s;
}

.fadeIn.second {
  animation-delay: 0.6s;
}

.fadeIn.third {
  animation-delay: 350ms;
}

.fadeIn.fourth {
  animation-delay:300ms;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #56baed;
  content: '';
  transition: width 0.2s;
}

.underlineHover:hover:enabled {
  color: #0d0d0d;
}

.underlineHover:hover:enabled:after {
  width: 100%;
}

.logo {
  width: 300px;
}

@keyframes opacity {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}

.footer {
  background-color: #f8f9fa;
  max-height: 5.25rem;
}

.footer:before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background: #ccc;
  top: -30px;
  left: 0;
}

.text-red {
  color: #d20606;
}

.footer > div {
  padding: 1rem 0;
}

.text-vhumanize {
  color: #4e73df;
}

.btn-vhumanize {
  color: white;
}

h1 > span,
h2 > span {
  color: #36b9cc;
  text-decoration: underline;
  font-weight: 500;
}

.text-newskin,
.form-group label,
h3,
h4,
h5 {
  color: #5b5d5d;
}

.text-newskin > i,
.form-group label > i,
h1 > i,
h2 > i,
h3 > i,
h4 > i,
h5 > i {
  color: #ccc;
  -webkit-text-fill-color: #ccc;
}

.text-position-title {
  color: #4fc2d0;
}

@media (max-width: 576px) {
  .form-signin {
    padding: 0 20px 50px;
  }

  .wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}

/* fade in from left*/

@keyframes aparece-izquierda {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

.fadeInfromLeft {
  animation: aparece-izquierda ease-in-out 1s backwards;
}

@keyframes aparece-derecha {
  from {
    transform: translateX(150%);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

.fadeInfromRigth {
  animation: aparece-derecha ease-in-out 1s backwards;
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.appear {
  animation: appear ease-in-out 2s;
  opacity: 1;
}

.appearLater {
  animation: appear ease-in-out 3s;
  opacity: 1;
}

td.rdtDay,
td.rdtMonth,
td.rdtYear {
  background: rgb(221, 221, 221);
  color: rgb(91, 105, 112);
}

/* @media (max-width: 715px) {
  h3 {
    color: gray !important;

    font-weight: bolder !important;
    font-size: 1.8rem !important;
  }

  h4 {
    color: rgb(13, 90, 114) !important;
    font-weight: 400;
  }

  h1 i {
    font-size: 1.5rem;
  }
  .adminName {
    font-size: 1.3rem;
    line-height: 6px;
  }
} */

.btn-vpsy-gradient {
  grid-gap: 9px;
  gap: 9px;
  border-radius: 50px;
  background-image: linear-gradient(to bottom, #22654e 0%, #0f402f 100%);
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: white !important;
}

.btn-vpsy-solid {
  grid-gap: 9px;
  gap: 9px;
  border-radius: 50px;
  background-image: linear-gradient(to right, #3c7674 0%, #22654e 100%);
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: white !important;
}

.btn-vpsy-outline {
  grid-gap: 9px;
  gap: 9px;
  border-radius: 50px;
  border-style: solid;
  border-width: 1px;
  border-color: #22654e;
  font-weight:400;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #22654e;
  background-color: #fff;
}

.btn-vpsy-outline button {
  background: unset;
}

.btn-vpsy-outline:hover {
  color: #fff;
  background-color: #22654e !important;
}

.vpsy-card {
  border-radius: 16px;
  box-shadow: 0 4px 30px 0 rgba(104, 104, 104, 0.1);
  background-color: #fff;
}

/* Tabs styles */
a.nav-link {
  text-decoration: none;
}

a.nav-link:hover {
  color: #22654e;
}

a.active {
  color: #22654e !important;
}

a.nav-font {
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #5b5d5d;
}

.swal-cancel-btn {
  border-style: solid !important;
  border-width: 1px !important;
  border-color: #22654e !important;
  font-weight:400;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #22654e !important; 
  background-color: #fff !important;
}
.container {
  align-content: center;
}

.form-signin {
  padding-top: 30px;
}

.form-signin input[type='email'] {
  margin-bottom: -1px;
}

.linkto {
  color: dimgray;
  text-align: center;
}

 a:hover:enabled {
  color: dimgray;
  text-align: center;
  text-decoration: none;
}

.forgot-pass-font {
  white-space: nowrap;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #517473;
}

.p-recover {
font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #517473;
}

.label-recover {
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.16px;
  text-align: left;
  color: #5b5d5d;
}

.back-recover {
  text-decoration: underline;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #517473;
}

div#login-container {
  max-height: 100%;
  max-width: 100%;
}

div#login-side-img {
  border-radius: 10% 0 0 10%;
  height: 100vh;
  width: 100vh;
  border: 2px green;
}

div#login-side-img div {
  float: left;
  top: 0px;
  z-index: 1000;
  background-color: rgba(34,101,78,0.5);
  border-radius: 10% 0 0 10%;
  min-height: 541px;
  height: 100%;
  width: 110%;
  position: absolute;
}

div#login-side-img img {
  border-radius: 10% 0 0 10%;
  object-fit: cover;
  min-height: 541px;
  height: 100%;
  width: 110%;
  position: relative;
  background-size: cover;
  background-position: 50% 50%;
  background-image: url();
}

.btn-login-font {
  text-wrap: nowrap;
  font-size: 15x;
  line-height: 2.2;
}

.btn-recover-pass {
  width: 152px;
  height: 21px;
  margin: 30px 57px 40px 18px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #517473;
  text-decoration: underline;
}

.login-alert {
  border-radius: 16px;
  border: solid 1px #f2b3ae;
  background-color: #ffebed;
}

.login-alert-text {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #bc0021;
}


@media screen and (max-width: 770px) {
  div#login-side-img {
    display: none;
  }
}
.subscription-container {
  border-width: 1px 0px;
  border-color: lightgrey;
  border-radius: 5px;
  margin: 4px;
  padding: 5px;
  justify-content: center;
}

.subscription-button {
  color: #FFF;
  background-color: #4FB04F;
  float: right;
  justify-self: center;
}

.subscription-button:hover:enabled {
  color: #FFF;
  background-color: #3FA03F;
}

.subscription-title {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.3rem;
  margin: 0.5rem;
  display: inline-block;
}
.mp{
  width: 100px;
  margin: auto;
}

.mp-button {
    width: inherit;
}
.button-submit {
  background-color: rgb(65, 163, 224);
  min-width: 150px;
  margin-bottom: 2rem;
  color: white;
  font-size: 1.2rem;
}

.button-submit:hover:enabled i {
  transition: 350ms;
  -webkit-transition: 350ms;
  -moz-transition: 350ms;
  -ms-transition: 350ms;
  -o-transition: 350ms;
  transform: rotate(360deg);
}

.button-submit:hover:enabled {
  color: wheat;
  background-color: rgba(4, 53, 73, 0.89);
  transition: background-color300ms ease-out;
}

h1 span {
  text-decoration: none;
  font-size: 1.9rem;
  font-family: 'Source Sans Pro', sans-serif;
  color: rgb(48, 56, 56);
}

.userForm {
  margin-top: 1rem;
  margin-bottom: 1rem;  
  border-radius: 16px;
  box-shadow: 0 4px 30px 0 rgba(104, 104, 104, 0.1);
  background-color: #fff;
}

.title {
  font-family: 'Source Sans Pro', sans-serif;
  /* font-size: 1.2rem; */
  margin: 0.5rem;
}

.subtitle-section,
.user-title-container {
  margin-bottom: 10px;
  display: flex;
}
.user-title-container h1,
.user-title-container h2,
.user-title-container h3,
.user-title-container h4,
.user-title-container h5 {
  margin: 0;
}
.text-position-title {
  color: #4fc2d0;
}
.user-buttons {
  display: flex;
  justify-content: flex-end;
  flex: 2 1;
}

.edit-profile-btn {
  width: 95%;
  background-color: transparent;
  color: #2097F3;
  border-color: #2097F3;
}

.edit-profile-btn:hover {
  color: #fff;
  background-color: #2097F3;
  border-color: #2097F3;
}

.changepass-btn {
  width: 95%;
  background-color: transparent;
  color: #FFA000;
  border-color: #FFA000;
}

.changepass-btn:hover {
  color: #fff;
  background-color: #FFA000;
  border-color: #FFA000;
}

.save-btn {
  background-color: transparent;
  color: #4FB04F;
  border-color: #4FB04F;
}

.save-btn:hover {
  color: #fff;
  background-color: #4FB04F;
  border-color: #4FB04F;
}

.edit-pass-label {
  text-decoration: underline;
}

.edit-pass-label:hover {
  cursor: pointer;
}
.spinner {
  position: absolute;
  z-index: 3;
}
.background {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.logoload {
  margin: 0 auto;
  padding: 10px;
}

.logoloader {
  max-width: 90%;
  width: 100px;
  animation: rotation 1.8s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate3d(1, 1, 1, 0deg);
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    transform: rotate3d(1, 1, 1, 360deg);
    opacity: 1;
  }
}

.pfp-input {
    display: none;
}

.pfp-img {
    cursor: pointer;
    object-fit: cover;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    background: #444;
}

.pfp-img:hover {
    filter: brightness(75%);
}

.no-hover {
    pointer-events: none;
}

.pfp-card {
    cursor: pointer;
    object-fit: cover;
    border-radius: 50%;
    height: 80px;
    width: 80px;
}

.pfp-card:hover {
    filter: brightness(75%);
}
.App {
  text-align: center;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
  background-color: rgb(170, 170, 170);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Google Font Link */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.topbar {
  height: 93px;
}

.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  width: 80px !important;
  height: 100vh;
  padding: 3px 10px;
  z-index: 990;
  border-right-style: solid;
  border-color: #eee;
  border-width: 1px;
  transition: all 0.3s ease;
  background-color: white;
}

.sidebar i {
  color: #fff;
  height: 60px;
  min-width: 50px;
  text-align: center;
  line-height: 60px;
}

.sidebar ul {
  overflow: visible;
}

.sidebar .nav-list {
  white-space: nowrap;
  margin-top: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sidebar li {
  position: relative;
  margin: 8px 0;
  list-style: none;
}

.sidebar li .tooltip {
  position: absolute;
  top: -20px;
  right: 3.5rem;
  z-index: 3;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 400;
  opacity: 0;
  pointer-events: none;
  transition: 0s;
}

.sidebar li:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  transition: all 0.3s ease;
  top: 50%;
  transform: translate(130%, -50%);
}

.sidebar.open li .tooltip {
  display: none;
}

.butn {
  padding: 1px !important;
  border-radius: 50%;
  margin-top: 10px;
}

.activeButton {
  margin: 10px 10px;
  transition: all 0.3s;
  border-radius: 10px;
  padding: 2px;
}

.sidebar.active {
  width: 260px !important;
  transition: all 0.3s;
  transform: translateX(0%) !important;
}

.sidebar li a {
  display: flex;
  height: 100%;
  width: 50%;
  border-radius: 12px;
  align-items: center;
  text-decoration: none;
  transition: all 0.3s ease;
  color: #5d5d5d;
  font-weight: 500;
  margin-left: 7px;
  float: left;
}

.sidebar li a:hover {
  color: rgb(0, 0, 0);
}

.sidebar li a:hover .links_name,
.sidebar li a:hover i {
  transition: all 0.3s ease;
  color: #11101D;
}

.sidebar li i {
  height: 50px;
  padding-right: 12px;
  line-height: 50px;
  border-radius: 12px;
  color: rgb(91, 91, 91);
  margin-right: 10px;
}

li .activeItem {
  float: right;
  width: 7px;
  border-right-style: solid;
  border-radius: 7px 0 0 7px;
  border-width: 7px;
  color: #3c7674;
  transform: translate(11px);
}

li .itemIndicator {
  position: relative;
  width: 7px;
  height: 100%;
}

.sidebar li .tooltip{
  position: absolute;
  z-index: 900;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 400;
  opacity: 0;
  pointer-events: none;
  transition: 0s;
}

.sidebar .active-icon {
  color: #3c7674;
}

.sidebar .active-font {
  color: #3c7674;
  font-weight: 700;
}

.arrowRight i {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  /* background-color: #c3c3c3;*/
  color: rgb(138, 7, 7);
  margin: 0px 15px 0px 0px;
  padding: 0;
  font-size: 55px;
  position: absolute;
  top: 10px;
  cursor: pointer;
  animation: rote 2s;
  animation: infin 1s infinite;
}


.arrowLeft i {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  /* background-color: #c3c3c3;*/
  color: rgb(138, 7, 7);
  margin: 0px 15px 0px 0px;
  font-size: 55px;
  transform: rotate(180deg);
  position: absolute;

  top: 12px;
  cursor: pointer;
  animation: rote 2s;
  animation: infin 1s infinite;

}


@keyframes infin {
  0% {
    right: 210px;
  }

  50% {
    right: 213px;
  }

  100% {
    right: 210px;
  }
}

#navItem {
  font-size: 1.4rem;
}

.navLogo {
  height: 3.5rem;
  min-height: 3.5rem;
}

.topBar {
  padding-left: 80px;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: start;
  align-items: center;
  align-content: stretch;
  transition: all 0.3s;
  border-bottom-style: solid;
  border-color: #eee;
  border-width: 1px;
}

.topBar.active {
  padding-left: 260px !important;
}

.topBar i {
  font-size: 1.4rem;
}

/* .topBar .searchBar {
  width: 30em;
  margin-left: 2.6rem;
} */

.patient-searchbar{
  width: 366px;
  height: 43px;
  margin: 3px 645.5px 0 28px;
  padding: 13px 23px 11px 22px;
  border-radius: 50px;
  box-shadow: 30px 70px 120px 0 rgba(27, 49, 66, 0.13);
  border: solid 1px #f3f3f3;
  background-color: #fff;
}

.topBar-username {
  font-family: Poppins;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5b5d5d;
}

.topBar-edit-profile {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}

.topBar-profile-box {
  min-width: 10%;
  border-left-style: solid;
  border-width: 1px;
  border-color: #ccc;
} 

.navbar-margin {
  margin-left: 80px;
}

@media screen and (max-width: 770px) {
  .topBar {
    padding-left: 0px;
  }

  .sidebar {
    transform: translateX(-100%);
  }
  .navbar-margin {
    margin-left: 0px;
  }
}
.fill-height {
  min-height: 90vh;
}

table .td,
th {
  vertical-align: middle;
}

.button-patient {
  color: #FFF;
  background-color: #4FB04F;
  min-width: 60px;
  -webkit-box-shadow: 2px 3px 10px #999;
  -moz-box-shadow: 2px 3px 10px #999;
  /* font-size: 1.2rem; */
  border-radius: 8px;
  /* width: 12rem;
  height: 40px; */
}

.button-patient:hover {
  color: #FFF;
}

.button-patient i {
  margin: 4px;
}

.button-patient:hover:enabled i {
  transition: 350ms;
  -webkit-transition: 350ms;
  -moz-transition: 350mss;
  -ms-transition: 350ms;
  -o-transition: 350ms;
  transform: rotate(360deg);
  font-size: 18px;
}

.button-patient:hover:enabled {
  color: #FFF;
  transition: background-color300ms ease-out;
  box-shadow: inset -31px -32px 20px -28px rgba(63, 63, 63, 0.16),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
}

.button-filter:hover:enabled {
  color: #FFF;
  transition: background-color300ms ease-out;
  box-shadow: inset -31px -32px 24px -25px rgba(63, 63, 63, 0.12),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
}

.button-filter {
  border-radius: 10px;
  color:  #FFF;
  background-color: #2097F3;
  -webkit-box-shadow: 2px 3px 10px #999;
  -moz-box-shadow: 2px 3px 10px #999;
}

.button-filter:hover:enabled i {
  transition: 350ms;
  transform: rotate(360deg);
}

.button-patient:hover:enabled {
  color: #FFF;
  transition: background-color300ms ease-out;
  box-shadow: inset -31px -32px 20px -28px rgba(63, 63, 63, 0.16),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
}

.button-filter-rst {
  border-radius: 10px;
  color:  #FFF;
  background-color: #FFA000;
  -webkit-box-shadow: 2px 3px 10px #999;
  -moz-box-shadow: 2px 3px 10px #999;
}

.button-filter-rst:hover:enabled {
  color: #FFF;
  transition: background-color300ms ease-out;
  box-shadow: inset -31px -32px 24px -25px rgba(63, 63, 63, 0.12),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
}

.button-filter-rst:hover:enabled i {
  transition: 350ms;
  transform: rotate(360deg);
}

.button-submit {
  background-color: rgb(65, 163, 224);
  min-width: 150px;
  margin-bottom: 2rem;
  color: white;
  font-size: 1.2rem;
  -webkit-box-shadow: 2px 3px 10px #999;
  -moz-box-shadow: 2px 3px 10px #999;
}

.button-submit:hover:enabled i {
  transition: 350ms;
  -webkit-transition: 350ms;
  -moz-transition: 350ms;
  -ms-transition: 350ms;
  -o-transition: 350ms;
  transform: rotate(360deg);
}

.button-submit:hover:enabled {
  color: wheat;
  background-color: rgba(4, 53, 73, 0.89);
  transition: background-color300ms ease-out;
}

.icon-hover:hover:enabled {
  transition: 350ms;
  -webkit-transition: 350ms;
  -moz-transition: 350ms;
  -ms-transition: 350ms;
  -o-transition: 350ms;
  transform: rotate(360deg);
}

.table-patient {
  border-collapse: collapse;
  border-radius: 22px;
  overflow: hidden;
}

.tablePatient {
  background-color: #F4FCFF;
  color: rgb(56, 56, 56);
  font-size: 1.1rem;
}
.tablePatient td {
  color: rgb(134, 134, 134);
  font-size: 14px;
  padding: 0 10px;
  vertical-align: middle;
}

.headPatient tr th {
  font-family: Poppins;
  font-weight: 400;
  background-image: linear-gradient(to bottom, #3c7674 0%, #22654e 100%);
  padding: 5px 20px;
  color: white;
  height: 40px;
  vertical-align: middle;
}

.td-patient-name {
  width: 151px;
  height: 18px;
  margin: 1px 40px 3px 73px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5b5d5d;
}

.td-patient-name:hover {
  cursor: pointer;
}

.patient-title {
  font-family: Poppins;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  text-align: left;
  color: #517473;
}

.patient-title-arrow {
  position: relative;
  right: 0.6rem;
  color: #517473;
}

.patient-label {
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5b5d5d;
}

.patient-input {
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5b5d5d;
}

.patient-input-phone {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}

.patient-input-description {
  height: 182px !important;
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5b5d5d;
}

.patient-form-card {
  background-color: white;
  border-radius: 16px;
}

.icon-red {
  color: #aa1313;
}

.no-results {
  font-family: Poppins;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  text-align: left;
  color: #517473;
}

.no-results a {
  color: #6ca6a4;
  font-size: 0.88rem;
  font-family: Poppins;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5;
  letter-spacing: 1.08px;
  text-decoration: underline;
}

.td-exam-status {
  width: 151px;
  height: 18px;
  margin: 1px 40px 3px 73px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5b5d5d;
}

.td-exam {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.td-exam:hover {
  cursor: pointer;
}
span.page-link {
  cursor: pointer;
}

.page-item.active .page-link {
  z-index: 0;
}

.page-item.active .page-link {
  background-color: rgb(173, 173, 173);
  border-color: rgb(173, 173, 173);
}

.page-item {  
  cursor: pointer;
}

span .page-arrow {
  width: 6px;
  height: 10px;
  flex-grow: 0;  
  cursor: pointer;
}

span .page-text {
  width: 70px;
  height: 18px;
  flex-grow: 0;
  font-family: Poppins;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #5b5d5d;
}
/* ONBOARDING STYLE */

.onboarding-steps {
    box-shadow: inset 0px -5px 13px -5px rgb(119 119 119 / 47%);
    border-radius: 10px;
    min-width: 350px !important;
  }
.fill-height {
  min-height: 90vh;
}

.icon-hover:hover:enabled {
  transition: 350ms;
  -webkit-transition: 350ms;
  -moz-transition: 350ms;
  -ms-transition: 350ms;
  -o-transition: 350ms;
  transform: rotate(360deg);
}

.button-exam {
  color: #FFF;
  background-color: #4FB04F;
  min-width: 60px;
  -webkit-box-shadow: 2px 3px 10px #999;
  -moz-box-shadow: 2px 3px 10px #999;

  border-radius: 8px;
  /* width: 10rem; */
  height: 40px;
}

.button-exam i {
  margin: 4px;
}

.button-exam:hover:enabled i {
  transition: 350ms;
  -webkit-transition: 350ms;
  -moz-transition: 350ms;
  -ms-transition: 350ms;
  -o-transition: 350ms;
  transform: rotate(360deg);
}

.button-exam:hover:enabled {
  color: #FFF;
  transition: background-color300ms ease-out;
  box-shadow: inset -31px -32px 20px -28px rgba(63, 63, 63, 0.16),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
}

.button-deleteCategory {
  padding-bottom: 0.6rem;
  border-radius: 10px;
  color: rgb(105, 105, 105);
  background-color: #fff;
  font-weight: bold;
}

.button-deleteCategory:hover:enabled {
  transition: background-color300ms ease-out;
  color: red;
  background-color: rgb(221, 221, 221);
}

.button-deleteExam:hover:enabled {
  color: rgb(71, 71, 71);
  transition: background-color300ms ease-out;
  box-shadow: inset -31px -32px 24px -25px rgba(63, 63, 63, 0.12),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
  color: red;
  background-color: rgb(221, 221, 221);
}

.button-deleteExam {
  border-radius: 10px;
  color: #aa2b2b;
  background-color: #fff;
  font-weight: bold;
}
.button-deleteExam:hover:enabled i {
  transition: 350ms;
}

.table-fixed {
  table-layout: fixed;
}

.tableExams {
  background-color: rgba(255, 255, 255, 0.9);
}

.tableExams td{
text-align: center;
}

.headExams {
  height: 60px;
}

.headExams label {
  color: rgb(56, 56, 56);
  font-size: 0.7rem;
}

.zScoreCellGray {
  color: #606060;
  border: 0.2px solid rgb(192, 190, 190);
}

.zScoreCellGreen {
  color: #1fac26;
  border: 0.2px solid rgb(192, 190, 190);
}

.zScoreCellYellow {
	color: #c7cb20;
  border: 0.2px solid rgb(192, 190, 190);
}

.zScoreCellRed {
	color: #aa1f1f;
  border: 0.2px solid rgb(192, 190, 190);
}

.headExams td {
  border: solid 0.8px rgba(124, 174, 173, 0.24);
  color: rgb(122, 122, 122);
}

.headExams th {
  color: #5b5d5d;
  font-size: 12px;
  padding: 1px;
  text-align: center;
  background-color: #fff;
  border: solid 0.8px rgba(124, 174, 173, 0.24);
  font-family: Poppins;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
}
.headExams th hr {
  width: 25px;
  margin-top: 5px;
  margin-bottom: 5px;
}

/* COLUMNS WIDTH */
.headExams tr .col-test-type {
  min-width: 10.5rem;
  width: 13.1rem;
}

.tableExams td {
  text-align: center;
  background-color: #f7f7f7;
}

tr .col-test-name {
  font-family: Poppins;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.76;
  letter-spacing: normal;
  text-align: center;
  color: #5b5d5d;
}


@media (max-width: 715px) {
  .button-deleteExam {
    width: 100%;
    margin-bottom: 10px;
  }
}
.tableExams .form-control:disabled, .tableExams .form-control[readonly], .tableExams.form-control  {
  text-align: center;
  margin-left: 2px;
  margin-right: 2px;
}


.btn-add-stdy {
  color: #696969;
  background-color: #FFF;
  min-width: 150px;
  -webkit-box-shadow: 2px 3px 10px #999;
  -moz-box-shadow: 2px 3px 10px #999;

  border-radius: 8px;
  width: 10rem;
  height: 40px;
}

.btn-add-stdy:hover:enabled {
  color: #000;
  transition: background-color300ms ease-out;
  box-shadow: inset -31px -32px 20px -28px rgba(63, 63, 63, 0.16),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
}

.btn-add-stdy-arrow {
  color: #FFF;
  background-color: #4FB04F;
  max-width: 4rem;
  min-width: 1.5rem;
  -webkit-box-shadow: 2px 3px 10px #999;
  -moz-box-shadow: 2px 3px 10px #999;
  border-radius: 8px;
  height: 40px;
}

.btn-add-stdy-arrow:hover:enabled {
  color: #FFF;
  transition: background-color300ms ease-out;
  box-shadow: inset -31px -32px 20px -28px rgba(63, 63, 63, 0.16),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
}

.btn-add-stdy i {
  margin: 4px;
}

.btn-add-stdy:hover:enabled i {
  transition: 350ms;
  -webkit-transition: 350ms;
  -moz-transition: 350ms;
  -ms-transition: 350ms;
  -o-transition: 350ms;
  transform: rotate(360deg);
}

.btn-dl-pdf {
  color: rgb(105, 105, 105);
  background-color: rgb(255, 255, 255);
  min-width: 190px;
  border-radius: 19px;
  height: 38px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.btn-dl-pdf:hover:enabled {
  color: rgb(71, 71, 71);
  transition: background-color300ms ease-out;
  box-shadow: inset -31px -32px 20px -28px rgba(63, 63, 63, 0.16),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
}

.btn-dl-pdf i {
  margin: 4px;
}

.animated-glow {
  animation: glow 750ms infinite alternate;
}

@keyframes glow {
  from {
    box-shadow: 0 0 5px -5px #FFA000;
  }
  to {
    box-shadow: 0 0 5px 5px #FFA000;
  }
}

/* Removing arrows from number input */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .exam-title {
    font-family: Poppins;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: left;
    color: #517473;
  }

  .exam-card {
    overflow: hidden;
    border-radius: 16px;
    background-color: #fcfcfc;
    min-height: 83px;
  }

  .exam-card .exam-card-topbar {
      height: 19px;
      background-image: linear-gradient(to bottom, #3c7674 0%, #22654e 100%);
  }

  .exam-card-section {
    background-color: #fff;
  }

  .new-exam-input {
    width: -webkit-fill-available;
    height: 45px;
    border: solid 0.8px rgba(124, 174, 173, 0.24);
    flex-grow: 0;
    margin: 0px 0.1px 0 0;
    font-family: Poppins;
    font-size: 12.4px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #5b5d5d;
  }

.category-label {
  font-family: Poppins;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  align-self: center;
  color: #5b5d5d;
}

.test-tables {
  max-height: 350px;
  overflow-y: scroll;
}

.test-select-btn {
  width: 150px;
  height: 75px;
  flex-grow: 0;
  border-radius: 11.7px;
  background-color: rgba(124, 174, 173, 0.1);
}

.test-select-btn:hover {
  border: solid 0.7px #22654e;
}

.test-select-btn-txt {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #5b5d5d;
 }

  .test-select-icon {
    font-size: 30px;
  }

  .exam-content-min-height {
    min-height: 400px;
  }

  @media screen and (max-width: 770px) {
    .md-hide {
      display: none;
    }
    .md-full-width {
      max-width: 100%;
    }
  }

  .watch-video-banner {
    height: 121px;
    flex-grow: 0;
    border-radius: 16px;
    box-shadow: 0 4px 15px 0 rgba(104, 104, 104, 0.1);
    background-color: transparent;
    border-style: solid;
    border-width: 2px;
    border-color: #22654e;
    animation: glow 750ms infinite alternate;
  }
  
  .watch-video-banner-icon {
    color: #22654e;
    font-size: 3.9rem;
  }
  
  .watch-video-banner-text {
    font-size: 1.4rem;
    font-family: Poppins;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: 1.08px;
    color: #22654e
  }
  
  .watch-video-banner-link {
    color: #22654e;
    font-size: 0.88rem;
    font-family: Poppins;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: 1.08px;
    text-decoration: underline;
  }
  .watch-video-banner-link:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 1200px) {
    .watch-video-banner-icon {
      display: none;
    }
    .watch-video-banner-text {
      font-size: 0.8rem;
    }
  }
  
  @media screen and (max-width: 770px) {
    .watch-video-banner {
      display: none;
    }
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 5px -5px #72B59e;
    }
    to {
      box-shadow: 0 0 5px 5px #72B59e;
    }
  }
  

  .flip-card {
    height: 121px;
    background-color: transparent;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateX(180deg);
  }

  .flip-card .flip-card-inner {
    animation: mymove 8s infinite alternate;
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
  }
  
  .flip-card-back {
    transform: rotateX(180deg);
  }

  @keyframes mymove {
    0%   {transform: rotateX(0deg);}
    45%  {transform: rotateX(0deg);}
    55%  {transform: rotateX(180deg);}
    100% {transform: rotateX(180deg);}
  }
.exam-sidebar {
    height: 400px;
    background-color: #fcfcfc;
}

.exam-sidebar .active{
    color: #22654e;
}

.exam-sidebar .inactive{  
    color: #b2b1b1;
}

.exam-sidebar-item {
    width: 150px;
    height: 19px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.exam-sidebar-item i {
    font-size: 20px;
    margin-right: 5px;
}

.cursor-pointer {
	cursor: pointer;
}

.exam-sidebar-sub-item {
    font-family: Poppins;
    font-size: 10px;
    color: #5b5d5d;
}

.exam-btn-txt {
  flex-grow: 0;
  font-family: Poppins;
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.75;
  letter-spacing: normal;
  text-align: center;
} 

.exam-section-title {
    /* width: 100%; */
    height: 27px;
    flex-grow: 0;
    /* margin: 0 708px 9px 0; */
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5b5d5d;
}

.exam-line-green {
    width: 90px;
    border-style: solid;
    border-width: 5px;    
    border: solid 1px #3c7674;
}

.exam-line-divider {
    width: 95%;
    border: solid 1px #e1e1e1;
    align-self: center;
}

.exam-input-label {
    margin: 0 370px 1px 12px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5b5d5d;
}

.exam-sidebar-divider {
    height: 10%;
    border-left: solid 1px #d2d1d1;
    flex-shrink: 1;
}

.exam-nav-tabs {
	white-space: nowrap;
	overflow-x: auto;
	overflow-y: hidden;
	flex-wrap: nowrap;
}
/* SECTION'S TEXT INPUT */
.section-title {
  font-weight: bolder;
}

.section-text {
  border-radius: 8px;
  border-color: #ddd;
  box-shadow: 0 4px 30px 0 rgba(104, 104, 104, 0.1);
  background-color: #fff;
}

.section-text:focus-visible{
  outline: 2px solid #2684ff;
}

/* DELETE SECTION BUTTON */
.bt-delete {
  float: right;
  margin-bottom: 1rem;
  color: rgb(105, 105, 105);
  background-color: rgb(255, 255, 255);
  min-width: 60px;
  -webkit-box-shadow: 2px 3px 10px #999;
  -moz-box-shadow: 2px 3px 10px #999;
  font-size: 1rem;
  border-radius: 8px;
  width: 12rem;
  height: 35px;
}

.bt-delete:hover:enabled {
  color: rgb(184, 0, 0);
  transition: background-color 100ms ease-out;
  box-shadow: inset -31px -32px 24px -25px rgba(63, 63, 63, 0.12),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
}

.bt-delete:hover:enabled i {
  transition: 350ms;
  transform: rotate(360deg);
}

/* ADD SECTION BUTTON */

.bt-section {
  color: rgb(105, 105, 105);
  background-color: rgb(255, 255, 255);
  border-color: #fff;
  min-width: 60px;
  border-radius: 8px;
  -webkit-box-shadow: 2px 3px 10px #999;
  -moz-box-shadow: 2px 3px 10px #999;
}

.bt-section:hover:enabled {
  color: rgb(105, 105, 105);
  background-color: #fff;
  transition: background-color 100ms ease-out;
  box-shadow: inset -31px -32px 24px -25px rgba(63, 63, 63, 0.12),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
}

.bt-section:hover:enabled i {
  transition: 350ms;
  transform: rotate(360deg);
}

.conclusion-list-item {
  border-radius: 21.5px;
  border: solid 1px rgba(124, 174, 173, 0.24);
  background-color: rgba(124, 174, 173, 0.24);
  font-size: 12px;
}

.add-conclusion-btn {
  cursor: pointer;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #22654e;
  text-decoration: underline;
}

.conclusion-modal-title {
  font-family: Poppins;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  text-align: left;
  color: #22654e;
}

.no-conclusions-sign {
  padding: 10% 0 20%;
  margin-bottom: 20%;
  border-radius: 16px;
  background-image: linear-gradient(to bottom, #3c767411 0%, #ffffff11 100%);
}

.no-conclusions-sign i {
  font-size: 68px;
  color: #22654e;
}

.no-conclusions-sign .no-conclusions-text {
  font-family: Poppins;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: center;
  color: #22654e;
}
.tiptap :first-child{margin-top:0}.tiptap table{border-collapse:collapse;margin:0;overflow:hidden;table-layout:fixed;width:100%}.tiptap table td,.tiptap table th{border:1px solid #aaa;box-sizing:border-box;min-width:1em;padding:6px 8px;position:relative;vertical-align:top}.tiptap table td>*,.tiptap table th>*{margin-bottom:0}.tiptap table th{background-color:#ddd;font-weight:bold;text-align:left}.tiptap table .selectedCell:after{background:#ddd;content:"";left:0;right:0;top:0;bottom:0;pointer-events:none;position:absolute;z-index:2}.tiptap table .column-resize-handle{background-color:#838;bottom:-2px;pointer-events:none;position:absolute;right:-2px;top:0;width:4px}.tiptap .tableWrapper{margin:1.5rem 0;overflow-x:auto}.tiptap.resize-cursor{cursor:ew-resize;cursor:col-resize}.my-custom-class{border:1px solid #ccc}
.yt-thumbnail {
    border-radius: 8px;
}

.helpcard-title {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    color: #5b5d5d;
}

.helpcard-description {
    font-family: Poppins;
    font-size: 14px;
    line-height: 1.63;
    color: #5b5d5d;
}

@media(max-width: 800px) {
    .modal-dialog {
        margin: 0 !important;
    }
}

card {
  width: 18rem;
}

.margin-left {
  margin-left: 5rem;
}

@media (max-width: 420px){
  .category{
    margin-left: 0 !important;
  }
  .category-hidden{
    display: none;
  }
  .provider{
    margin: 0 !important;
    display: inline-block !important;
  }
  .provider-name{
    margin-right: 0 !important;
  }
  .postal-code{
    margin-top: 1rem !important;
  }
}

@media (min-width: 1025px ){

  .category-hidden-lg{
    display: none;
  }
}

.margin-right {
  margin-right: 11.5rem;
}

.margin {
  margin-left: 1rem;
}

.margin-top {
  margin-top: 1rem;
}
.overlay {
  opacity: 0;
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 0.5s;
}
.fold-background {
  background-color: #d6eaf8;
}

h1 {
  margin: 2rem 0 1rem;
  color: #4fc2d0;
  margin-top: 10px;
}

.jumbotron {
  background-size: cover;
  margin-top: 20px;
  text-align: center;
}

.table {
  border-collapse: collapse;
}

.jumbotron span {
  color: #36b9cc !important;
}

.clickable {
  cursor: pointer;
}

.clickable:hover:enabled {
  color: #36b9cc;
}

@media (max-width: 576px) {
  .jumbotron {
    padding-bottom: 50px;
  }
}

.creation-info {
  border-radius: 1rem;
  border: solid #858796 2px;
}

.horizontal-scrollable {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  min-width: 1060px;
}
.modal-backdrop.show {
    z-index: 1040 !important;
}
.attachment-label {
    height: 50%;
    margin: 0;
}

.attachment-item {
    padding: 0em 0.88rem;
}
table .td,
th {
  vertical-align: middle;
}

.button-appointment {
  color: white;
  flex-grow: 0;
  border-radius: 19px;
  box-shadow: 30px 70px 120px 0 rgba(27, 49, 66, 0.13);
  border: solid 1px rgba(243, 243, 243, 0);
  background-image: linear-gradient(to right, #3c7674 0%, #22654e 100%);
  font-family: Poppins;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /* Overlaying over calendar */
  z-index: 1;
  text-align: left;    
  position: relative;
  top: 3.5rem;
}

.button-appointment i {
  margin: 4px;
}

.button-appointment:hover:enabled {
  color: rgb(255, 255, 255);
  /* background-color: rgb(130, 140, 255); */
  -webkit-box-shadow: 2px 3px 10px rgb(0, 0, 0);
  transition: background-color300ms ease-out;
  box-shadow: inset -31px -32px 20px -28px rgba(63, 63, 63, 0.16),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
}

.add-patient-banner {
  height: 121px;
  flex-grow: 0;
  border-radius: 16px;
  box-shadow: 0 4px 15px 0 rgba(104, 104, 104, 0.1);
  background-image: linear-gradient(to bottom, #3c7674 0%, #22654e 100%);
}

.add-patient-banner-icon {
  color: white;
  font-size: 3.9rem;
}

.add-patient-banner-text {
  font-size: 1.4rem;
  font-family: Poppins;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5;
  letter-spacing: 1.08px;
}

.add-patient-banner-link {
  color: rgb(191, 238, 176);
  font-size: 0.88rem;
  font-family: Poppins;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5;
  letter-spacing: 1.08px;
  text-decoration: underline;
}

.appointment-popover {
  min-width: 250px;
  max-width: 350px;
}

.appointment-popover-button {
  font-size: 1.4rem;  
  color: #22654e;
}

.appointment-popover-button:hover {
  font-size: 1.4rem;
  color: #858796;
}

.appointment-popover-button-delete {
  font-size: 1.4rem;  
  color: #22654e;
}

.appointment-popover-button-delete:hover {
  font-size: 1.4rem;
  color: #b11010;
}

.appointment-popover-icons {
  font-size: 1.14rem;
  color: #22654e;
}

.appointment-popover-name {
  text-shadow: 0 4px 30px rgba(104, 104, 104, 0.1);
  font-family: Poppins;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #22654e;
}

.appointment-popover-item {
  text-shadow: 0 4px 30px rgba(104, 104, 104, 0.1);
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #5b5d5d;
}

.appointment-alert {
  border-radius: 30px;
  border: solid 1px #f2b3ae;
  background-color: #ffebed;
}

.appointment-alert-text {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #bc0021;
}

@media screen and (max-width: 1200px) {
  .add-patient-banner-icon {
    display: none;
  }
  .add-patient-banner-text {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 770px) {
  .add-patient-banner {
    display: none;
  }
}
/* Calendar component styles */

/* Calendar Border*/
.rs-calendar-table {
    background-color: white;
    border-collapse: separate;
    border-spacing: 3px 3px;
}

div .rs-calendar-panel .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    background-color: transparent;
    color: inherit;
    border: none;
    padding: 4px;
}

.rs-calendar {
    border-radius: 16px;
}

/* Title month year  */
.rs-calendar-panel .rs-calendar-header-title {
    width: 145px;
    margin: 0 15px 0 14px;
    font-family: Poppins;
    font-size: 22px !important;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18 !important;
    letter-spacing: normal;
    color: #517473;
}

/* DAYS TITLE ROW */
.rs-calendar-table-header-row {
    height: 31px !important;
}

/* DAYS TITLE CELL */
.rs-calendar-panel .rs-calendar-table-header-row .rs-calendar-table-cell {
    border: 0;
}

.rs-calendar-panel .rs-calendar-table-header-row .rs-calendar-table-cell-content {
    height: 31px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10.5px 0;
    border-radius: 3.5px;
    background-image: linear-gradient(to bottom, #3c7674 0%, #22654e 100%);
}

/* DAYS TITLE TEXT */
.rs-calendar-table-header-row .rs-calendar-table-cell-content {
    height: 25px;
    flex-grow: 0;
    font-family: Poppins !important;
    font-size: 17.5px !important;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4 !important;
    letter-spacing: normal;
    text-align: left;
    color: #fefff4 !important;
}

.rs-calendar-table-header-row .rs-calendar-table-cell-content:hover {
    pointer-events: none !important;
}

.rs-calendar-table-cell-content {
    cursor: default !important;
    height: auto !important; 
}

.rs-calendar-table-cell-content:hover {
    background-color: transparent !important;
}

.rs-calendar-table-header-row {
    pointer-events: none;
}

/* day cells row */
.rs-calendar-table-row {
    height: 145px;
}

/* Single day cell */
.rs-calendar-panel .rs-calendar-table-cell {
    height: 100%;
    margin: 1px;
    grid-gap: 12.2px;
    gap: 12.2px;
    border-radius: 3.5px;
    border: solid 1px #bbb;
}

/* day cell number */
.rs-calendar-panel .rs-calendar-table-cell-day {
    width: 22px;
    height: 25px;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 17.5px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    padding-left: 0.88rem;
}

/* Today number */
.rs-calendar-panel .rs-calendar-table-cell-is-today .rs-calendar-table-cell-day {
    color: #22654e !important;
    background-color: unset !important;
    width: 22px !important;
    height: 25px !important;
    margin-left: 0 !important;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 17.5px;
    font-weight: 600;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    padding-left: 0.88rem;
    -webkit-text-decoration: underline solid #42856e 4px;
            text-decoration: underline solid #42856e 4px;
}

/* PAST MONTH CELLS */
.rs-calendar-table-cell-un-same-month {
    border: 0 !important;
    background-color: rgba(124, 174, 173, 0.1);
}

/* Appointment item */
.appointments-container {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.appointment-item {
    margin-top: 3px;
    height: 23px;
    width: 100%;
    grid-gap: 3.5px;
    gap: 3.5px;
    padding: 0 2px;
    border-radius: 22px;
    font-size: 12px;
    word-wrap: nowrap;
}

.appointment-item a {
    vertical-align: -webkit-baseline-middle;
}


.appointment-item-online {
    color: #fff;
    background-color: #22654e;
    border-width: 1px;
    border-style: solid;
    border-color: #22654e;
    transition: all 100ms;
}

.appointment-item-in-person {
    color: #22654e;
    background-color: #fff;
    border-width: 1px;
    border-style: solid;
    border-color: #22654e;
    transition: all 100ms;
}

.appointment-item:hover {
    color: #153;
    background-color: #efe;
}

 /* Appointment red dot */
 .rs-badge-independent.rs-badge-dot {
    display: none;
 }
.btn-meeting {
  margin-top: 28px;
  width: 100%;
}
.del {
  float: right;
  margin: 1rem;
}

.button-submit {
  background-color: rgb(65, 163, 224);
  min-width: 150px;
  margin-bottom: 2rem;
  color: white;
  font-size: 1.2rem;
}

.button-submit:hover:enabled i {
  transition: 350ms;
  -webkit-transition: 350ms;
  -moz-transition: 350ms;
  -ms-transition: 350ms;
  -o-transition: 350ms;
  transform: rotate(360deg);
}

.button-submit:hover:enabled {
  color: wheat;
  background-color: rgba(4, 53, 73, 0.89);
  transition: background-color300ms ease-out;
}

.button-delete {
  float: right;
  margin: 1rem;
  color: rgb(105, 105, 105);
  background-color: rgb(255, 255, 255);
  height: 38px;
}

.button-delete:hover:enabled {
  color: rgb(184, 0, 0);
  box-shadow: inset -31px -32px 24px -25px rgba(63, 63, 63, 0.12),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
}

.extraButton,
.extraButton1 {
  color: rgb(105, 105, 105);
  background-color: rgb(255, 255, 255);
  min-width: 60px;
  -webkit-box-shadow: 2px 3px 10px #999;
  -moz-box-shadow: 2px 3px 10px #999;
  font-size: 1.1rem;
  border-radius: 8px;
  width: 10rem;
  height: 40px;
}
.extraButton:hover:enabled i,
.extraButton1:hover:enabled i {
  transition: 350ms;
  transform: rotate(360deg);
}

.extraButton1 {
  width: 13rem;
}

.btn-appointment-link {
  height: 38px;
  width: 100%;
  white-space: nowrap;
  margin-bottom: 1rem;
}

.left {
  text-align: center;
  display: flex;
  justify-content: center;
}

.logos {
  margin: 0px auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.landinglogo {
  max-width: 90%;
  width: 600px;
}
.landingiso {
  max-width: 90%;
  width: 210px;
}

.logo-vpsy {
  width: 20%;
}
.linkto {
  color: dimgray;
  text-align: center;
}

.linkto a {
  color: dimgray;
  text-align: center;
}

.subs-container {
  height: 85vh;
  /* padding-bottom: 10vh; */
}
.subs-container > h1 {
  text-align: center;
  /*font-family: 'Yusei Magic', sans-serif;
  font-size: 30px;
  margin-top:10px;*/
}

.about-container {
  height: 75vh;
}

h1 {
  /* font-family: 'Big Shoulders Stencil Text', cursive; */
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  /* margin-top: 2rem; */
  font-size: 3rem;
  color: rgba(82, 82, 82, 0.829);
}
.about-container > h1 {
  text-align: center;
  padding: 0;
  margin: auto;
  /*font-family: 'Yusei Magic', sans-serif;
    font-size: 30px;
    margin-top:10px;*/
}

.about-info {
  width: 90%;
  height: 65%;
  margin: auto;
  margin-top: 7rem;
}

.arrow {
  text-align: center;
  justify-content: center;
  /*position: absolute; El div será ubicado con relación a la pantalla*/
  left: 0px; /*A la derecha deje un espacio de 0px*/
  right: 0px; /*A la izquierda deje un espacio de 0px*/
  bottom: 15px; /*Abajo deje un espacio de 0px*/

  z-index: 0;
}

.info {
  width: 90%;
  margin: 5vh auto;
}

.infoText {
  line-height: 1.5;
  font-size: 1.2rem;
  width: 80%;
  margin: 1.5rem auto;
  /* font-family: 'Jost', sans-serif; */
  font-family: 'Source Sans Pro', sans-serif;
}

.icons i {
  margin: 3px auto;
  font-size: 2rem;
  color: rgb(138, 138, 138);
}

.cardStyles {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  height: 100%;
  font-size: 1rem;
  margin-top: 1rem;
}

.cardStyles:hover:enabled {
  box-shadow: rgba(0, 0, 0, 0.123) 0px 1px 2px, rgba(0, 0, 0, 0.219) 0px 2px 4px,
    rgba(0, 0, 0, 0.219) 0px 4px 8px, rgba(0, 0, 0, 0.123) 0px 8px 16px,
    rgba(0, 0, 0, 0.123) 0px 16px 32px, rgba(0, 0, 0, 0.123) 0px 32px 64px;
}

.cardStyles i {
  color: rgba(13, 81, 102, 0.829);
}

.brain {
  margin: 0 auto;
}

.imagen1 {
  height: 50%;
  width: auto;
  position: absolute;
  margin-left: 100px;
  margin-top: 120px;
  z-index: 40;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.imagen2 {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  height: 58%;
  width: auto;
  position: absolute;
  margin-top: -25px;
  margin-left: 115px;
  transform: rotate(8deg);
  z-index: 30;
}
.imagen3 {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  height: 60%;
  width: auto;
  margin-top: -40px;
  position: absolute;
  z-index: 25;
  transform: rotate(-12deg);
  margin-left: -60px;
}

.fadeInRight {
  animation: aparece-derecha ease-in-out 3s backwards;
}

.formLanding {
  margin: 0 auto;
  border-radius: 10px 10px 10px 10px;
  background: rgba(241, 241, 241, 0.918);
  padding: 2rem;
  width: 600;
  height: auto;
  max-width: 450px;
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}

.formLanding .checkbox {
  font-weight: 300;
}
.formLanding .form-control {
  position: relative;
  box-sizing: border-box;
  height: 30px;
  padding: 10px;
  font-size: 16px;
}
.formLanding .form-control:focus {
  z-index: 2;
}

.formLanding input {
  margin-bottom: -1px;
  border-bottom-left-radius: 0;
  box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  -webkit-box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  -moz-box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  border: none;
}

.formLanding button {
  color: gray;
  margin-top: 20px;
  background-color: rgba(230, 230, 230, 0.808);
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 6px 7px 24px -4px rgba(150, 150, 150, 0.9);
  -webkit-box-shadow: 6px 7px 24px -4px rgba(150, 150, 150, 0.9);
  -moz-box-shadow: 6px 7px 24px -4px rgba(150, 150, 150, 0.9);
  border: none;
}

.formLanding button:hover:enabled {
  margin-top: 20px;
  background-color: rgba(180, 180, 180, 0.6);
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  -webkit-box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  -moz-box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  border: none;
}
.formLanding button:hover:enabled i {
  transition: 350ms;
  transform: rotate(360deg);
}

.formLanding #sign-up {
  color: #FFF;
  margin-top: 20px;
  background-color: #4FB04F;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 6px 7px 24px -4px rgba(150, 150, 150, 0.9);
  -webkit-box-shadow: 6px 7px 24px -4px rgba(150, 150, 150, 0.9);
  -moz-box-shadow: 6px 7px 24px -4px rgba(150, 150, 150, 0.9);
  border: none;
}

.formLanding #sign-up:hover:enabled {
  color: #FFF;
  margin-top: 20px;
  background-color: #3FA03F;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  -webkit-box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  -moz-box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  border: none;
}
.formLanding #sign-up:hover:enabled i {
  transition: 350ms;
  transform: rotate(360deg);
}

.info-adv {
  width: 90%;
  margin: 5% auto;
}

.subs strong {
  color: #22654e;
}

.subs {
  font-size: 1.5rem;
}
.smallInfo {
  font-size: 1rem;
  color: rgb(79, 47, 51);
  /* margin-top: 2rem; */
  background-color: rgba(75, 75, 75, 0.281);
  padding: 10px;
  border-radius: 20px;
}

.redes {
  width: 60%;
  height: 80px;
  display: flex;
  margin: 10px auto;
}

.mercadoPago {
  width: 60px;
  margin: auto;
}

 .images{
   position:relative;
 }

@media (max-width: 500px){
  .about-info {
    margin-top:2px;
  }
  .images{
    position:relative;
    width: 85vw !important;
    height: 80vh;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  .imagen1 {
    width:85% !important;
    height: auto;
     z-index: 40;
     margin-top: 330px!important;
     margin-left: 10px!important;
     
   }
   
   .imagen2 {
     
    width:80% !important;
    height: auto;
    margin-top: 210px !important;
    margin-left: 50px!important;
     transform: rotate(8deg);
     z-index: 30;
   }
   .imagen3 {
     width: 80% !important;
     height: auto;
     margin-top: 10px !important;
     z-index: 25;
     transform: rotate(-12deg);
     margin-left: -20px!important;
  
   }
  .arrow {
    text-align: center;
    justify-content: center;
    position: -webkit-sticky;
position: sticky;
top: 20px;
    
  
    z-index: 50;
  }

  .box {
  margin-bottom: 20px;
  }
  .background{
    visibility: hidden;
  }

  .about-container > h1{
    padding-top: 40px!important;
  }

  .infoText {
    width: 80% !important;
    margin-top:30px !important;

  }
  .redes {
    width: 90%;
    height: 80px;
    display: flex;
    margin: 10px auto;
    margin-top: 30px;
  }
  
  .about-info {
    width: 100% !important;
    height: auto;
    margin: auto;
    margin-top: 2rem;
  }
  

  }



@media (min-width: 501px) and (max-width: 991px){
  
  .box{
    margin-bottom: 20px !important;
  }
  .infoText {
    width: 95% !important;
    margin: 1rem auto;

  }

 
  .images{
   
    width: 85vw !important;
    height: 80vh;
    margin-top: 40px;
    margin-left: 10%;
    margin-right: 10%;
  }

  .imagen1 {
    width:70% !important;
    height: auto;
     z-index: 40;
     margin-top: 200px!important;
     margin-left: 100px!important;
     
   }
   
   .imagen2 {
     
    width:50% !important;
    height: auto;
    margin-top: 20px !important;
     transform: rotate(8deg);
     z-index: 30;
     margin-left: 250px!important;
   }
   .imagen3 {
     width: 50% !important;
     height: auto;
     z-index: 25;
     transform: rotate(-12deg);
     margin-left: 20px!important;
  
   }
  .arrow {
   
    position: -webkit-sticky;
   
    position: sticky; 
    z-index: 40;
  }
}


@media (max-width: 1300px){
  .infoText {
    width: 50%;
    margin: 1rem auto;

  }
  .about-info {
    width: 100% !important;
    height: auto;
    margin: auto;
    margin-top: 2rem;
  }
  
  .images{
   margin-right: 20px !important;
   max-height: 50vh;
  }

  .imagen1 {
   width:80%;
   height: auto;
    position: absolute;
    margin-left: 5px;
    margin-top: 130px;
    z-index: 40;
    
  }
  
  .imagen2 {
    
   width:70%;
   height: auto;
    position: absolute;
    margin-top: -10px;
    margin-left: 100px;
    transform: rotate(8deg);
    z-index: 30;
  }
  .imagen3 {
    width: 75%;
    height: auto;
    margin-top: -20px;
    position: absolute;
    z-index: 25;
    transform: rotate(-12deg);
    margin-left: -90px;
  }

}
.login-sign {
  color: #fff;
  background-color: #2097f3;
  -webkit-box-shadow: 2px 3px 10px rgb(0, 64, 255);
}

.login-sign:hover {
  color: #fff;
  background-color: #0077e3;
  transition: background-color300ms ease-out;
  box-shadow: inset -31px -32px 20px -28px rgba(63, 63, 63, 0.16),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
}

.btn-sign-up {
  color: #fff;
  background-color: #4fb04f;
  min-width: 60px;
  -webkit-box-shadow: 2px 3px 10px #999;
  -moz-box-shadow: 2px 3px 10px #999;
  border-radius: 8px;
  width: 10rem;
  height: 40px;
}

.btn-sign-up i {
  margin: 4px;
}

.btn-sign-up:hover:enabled i {
  transition: 350ms;
  -webkit-transition: 350ms;
  -moz-transition: 350ms;
  -ms-transition: 350ms;
  -o-transition: 350ms;
  transform: rotate(360deg);
}

.btn-sign-up:hover:enabled {
  color: #fff;
  transition: background-color300ms ease-out;
  box-shadow: inset -31px -32px 20px -28px rgba(63, 63, 63, 0.16),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
}

.signup-font{
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 3.52px;
  text-align: center;
}

.signup-card {
  padding: 40px 40px 30px 30px;
  border-radius: 16px;
  box-shadow: 0 4px 15px 0 rgba(104, 104, 104, 0.1);
  background-color: #fff;
}

.signup-card-title {
  white-space: nowrap;
  font-family: Poppins;
  font-size: 22px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #5b5d5d;
}

.center {
    text-align: center;
    display: flex;
    justify-content: center;
  }

.mp-text p{
    height: 100px;
    font-size: 1.2rem;
}

.logo-vpsy-mp {
  min-height: 70px;
  min-width: 242px;
}
.patient-summary {
    overflow: hidden;
    border-radius: 16px;
    background-color: #fff;
    min-height: 83px;
}

.patient-summary .patient-summary-topbar {
    height: 19px;
    background-image: linear-gradient(to bottom, #3c7674 0%, #22654e 100%);
}

.patient-summary .patient-name {
    display: flex;
    align-items: center;
    font-family: Poppins;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: left;
    color: #22654e;
}

.patient-name .patient-icon{
    font-size: 1.4rem;
    margin-right: 0.2rem;
}

.summary-top .edit-patient{
    color: #22654e;
    font-size: 12px;
    font-family: Poppins;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: 1.08px;
    text-decoration: underline;
}

.patient-summary .summary-top {
    height: 70px;
    align-content: center;
    border-bottom: 1px solid;
    border-color: rgba(124, 174, 173, 0.24);
}

.patient-data p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #5b5d5d;
}
.yt-thumbnail {
    border-radius: 8px;
}

.helpcard-title {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    color: #5b5d5d;
}

.helpcard-description {
    font-family: Poppins;
    font-size: 14px;
    line-height: 1.63;
    color: #5b5d5d;
}

@media(max-width: 800px) {
    .modal-dialog {
        margin: 0 !important;
    }
}

