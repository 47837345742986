.center {
    text-align: center;
    display: flex;
    justify-content: center;
  }

.mp-text p{
    height: 100px;
    font-size: 1.2rem;
}

.logo-vpsy-mp {
  min-height: 70px;
  min-width: 242px;
}