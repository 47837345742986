.exam-sidebar {
    height: 400px;
    background-color: #fcfcfc;
}

.exam-sidebar .active{
    color: #22654e;
}

.exam-sidebar .inactive{  
    color: #b2b1b1;
}

.exam-sidebar-item {
    width: 150px;
    height: 19px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.exam-sidebar-item i {
    font-size: 20px;
    margin-right: 5px;
}

.cursor-pointer {
	cursor: pointer;
}

.exam-sidebar-sub-item {
    font-family: Poppins;
    font-size: 10px;
    color: #5b5d5d;
}

.exam-btn-txt {
  flex-grow: 0;
  font-family: Poppins;
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.75;
  letter-spacing: normal;
  text-align: center;
} 

.exam-section-title {
    /* width: 100%; */
    height: 27px;
    flex-grow: 0;
    /* margin: 0 708px 9px 0; */
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5b5d5d;
}

.exam-line-green {
    width: 90px;
    border-style: solid;
    border-width: 5px;    
    border: solid 1px #3c7674;
}

.exam-line-divider {
    width: 95%;
    border: solid 1px #e1e1e1;
    align-self: center;
}

.exam-input-label {
    margin: 0 370px 1px 12px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5b5d5d;
}

.exam-sidebar-divider {
    height: 10%;
    border-left: solid 1px #d2d1d1;
    flex-shrink: 1;
}

.exam-nav-tabs {
	white-space: nowrap;
	overflow-x: auto;
	overflow-y: hidden;
	flex-wrap: nowrap;
}