.container {
  align-content: center;
}

.form-signin {
  padding-top: 30px;
}

.form-signin input[type='email'] {
  margin-bottom: -1px;
}

.linkto {
  color: dimgray;
  text-align: center;
}

 a:hover:enabled {
  color: dimgray;
  text-align: center;
  text-decoration: none;
}

.forgot-pass-font {
  white-space: nowrap;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #517473;
}

.p-recover {
font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #517473;
}

.label-recover {
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.16px;
  text-align: left;
  color: #5b5d5d;
}

.back-recover {
  text-decoration: underline;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #517473;
}
