.yt-thumbnail {
    border-radius: 8px;
}

.helpcard-title {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    color: #5b5d5d;
}

.helpcard-description {
    font-family: Poppins;
    font-size: 14px;
    line-height: 1.63;
    color: #5b5d5d;
}

@media(max-width: 800px) {
    .modal-dialog {
        margin: 0 !important;
    }
}
