.btn-meeting {
  margin-top: 28px;
  width: 100%;
}
.del {
  float: right;
  margin: 1rem;
}

.button-submit {
  background-color: rgb(65, 163, 224);
  min-width: 150px;
  margin-bottom: 2rem;
  color: white;
  font-size: 1.2rem;
}

.button-submit:hover:enabled i {
  transition: 350ms;
  -webkit-transition: 350ms;
  -moz-transition: 350ms;
  -ms-transition: 350ms;
  -o-transition: 350ms;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.button-submit:hover:enabled {
  color: wheat;
  background-color: rgba(4, 53, 73, 0.89);
  -webkit-transition: background-color300ms ease-out;
  -moz-transition: background-color300ms ease-out;
  -o-transition: background-color300ms ease-out;
  transition: background-color300ms ease-out;
}

.button-delete {
  float: right;
  margin: 1rem;
  color: rgb(105, 105, 105);
  background-color: rgb(255, 255, 255);
  height: 38px;
}

.button-delete:hover:enabled {
  color: rgb(184, 0, 0);
  -webkit-box-shadow: inset -31px -32px 24px -25px rgba(63, 63, 63, 0.12),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
  box-shadow: inset -31px -32px 24px -25px rgba(63, 63, 63, 0.12),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
}

.extraButton,
.extraButton1 {
  color: rgb(105, 105, 105);
  background-color: rgb(255, 255, 255);
  min-width: 60px;
  -webkit-box-shadow: 2px 3px 10px #999;
  -moz-box-shadow: 2px 3px 10px #999;
  font-size: 1.1rem;
  border-radius: 8px;
  width: 10rem;
  height: 40px;
}
.extraButton:hover:enabled i,
.extraButton1:hover:enabled i {
  transition: 350ms;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.extraButton1 {
  width: 13rem;
}

.btn-appointment-link {
  height: 38px;
  width: 100%;
  white-space: nowrap;
  margin-bottom: 1rem;
}