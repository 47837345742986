.patient-summary {
    overflow: hidden;
    border-radius: 16px;
    background-color: #fff;
    min-height: 83px;
}

.patient-summary .patient-summary-topbar {
    height: 19px;
    background-image: linear-gradient(to bottom, #3c7674 0%, #22654e 100%);
}

.patient-summary .patient-name {
    display: flex;
    align-items: center;
    font-family: Poppins;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: left;
    color: #22654e;
}

.patient-name .patient-icon{
    font-size: 1.4rem;
    margin-right: 0.2rem;
}

.summary-top .edit-patient{
    color: #22654e;
    font-size: 12px;
    font-family: Poppins;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: 1.08px;
    text-decoration: underline;
}

.patient-summary .summary-top {
    height: 70px;
    align-content: center;
    border-bottom: 1px solid;
    border-color: rgba(124, 174, 173, 0.24);
}

.patient-data p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #5b5d5d;
}