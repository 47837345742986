html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  background-image: linear-gradient(to bottom, #fff, #f1eeef);
}

.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-brand img {
  width: 45px;
}

.user-dropdown {
  width: 25px;
}

.vpsy-font-color {
  color: #22654e;
}

.vpsy-font {
  font-family: Poppins;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
}

.label-vpsy-font {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.16px;
  text-align: left;
  margin-left: 0.88rem;
}

.form-card {
  background-color: white;
  border-radius: 16px;
}

.form-control {
  border-radius: 50px !important;
  box-shadow: 30px 70px 120px 0 rgba(27, 49, 66, 0.13) !important;
  border: solid 1px rgba(201, 201, 201, 0.56) !important;
  background-color: #fff !important;
  font-size: 14px;
  height: 38px;
}

.form-signin {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 0 50px 50px;
  max-width: 450px;
  position: relative;
  padding-top: 0;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input[name='username'] {
  margin-bottom: -1px;

  border-bottom-left-radius: 0;
  box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  -webkit-box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  -moz-box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  border: none;
}
.form-signin input[type='password'] {
  margin-top: 10px;
  margin-bottom: 10px;

  border-top-right-radius: 0;
  box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  -webkit-box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  -moz-box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  border: none;
}

.form-signin button {
  color: gray;
  margin-top: 20px;
  background-color: rgba(211, 211, 211, 0.6);
  margin-bottom: 10px;
  border-radius: 50px;
  box-shadow: 6px 7px 24px -4px rgba(150, 150, 150, 0.9);
  -webkit-box-shadow: 6px 7px 24px -4px rgba(150, 150, 150, 0.9);
  -moz-box-shadow: 6px 7px 24px -4px rgba(150, 150, 150, 0.9);
  border: none;
}

.form-signin button:hover:enabled {
  color: #000;
  margin-top: 20px;
  background-color: rgba(180, 180, 180, 0.6);
  margin-bottom: 10px;
  box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  -webkit-box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  -moz-box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  border: none;
}
.form-signin button:hover:enabled i {
  transition: 350ms;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.form-signin #sign-up {
  color: #FFF;
  margin-top: 20px;
  background-color: #2097F3;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 6px 7px 24px -4px rgba(150, 150, 150, 0.9);
  -webkit-box-shadow: 6px 7px 24px -4px rgba(150, 150, 150, 0.9);
  -moz-box-shadow: 6px 7px 24px -4px rgba(150, 150, 150, 0.9);
  border: none;
}

.form-signin #sign-up:hover:enabled {
  margin-top: 20px;
  background-color: #1087D3;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  -webkit-box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  -moz-box-shadow: 6px 7px 24px -4px rgba(164, 169, 169, 0.88);
  border: none;
}
.form-signin #sign-up:hover:enabled i {
  transition: 350ms;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.logo-login {
  margin: 10px 30px;
}

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration:300ms;
  animation-duration:300ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

#wrap,
.wrapper {
  min-height: 100%;
}

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 20px;
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration:300ms;
  -moz-animation-duration:300ms;
  animation-duration:300ms;
}

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 350ms;
  -moz-animation-delay: 350ms;
  animation-delay: 350ms;
}

.fadeIn.fourth {
  -webkit-animation-delay:300ms;
  -moz-animation-delay:300ms;
  animation-delay:300ms;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #56baed;
  content: '';
  transition: width 0.2s;
}

.underlineHover:hover:enabled {
  color: #0d0d0d;
}

.underlineHover:hover:enabled:after {
  width: 100%;
}

.logo {
  width: 300px;
}

@keyframes opacity {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}

.footer {
  background-color: #f8f9fa;
  max-height: 5.25rem;
}

.footer:before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background: #ccc;
  top: -30px;
  left: 0;
}

.text-red {
  color: #d20606;
}

.footer > div {
  padding: 1rem 0;
}

.text-vhumanize {
  color: #4e73df;
}

.btn-vhumanize {
  color: white;
}

h1 > span,
h2 > span {
  color: #36b9cc;
  text-decoration: underline;
  font-weight: 500;
}

.text-newskin,
.form-group label,
h3,
h4,
h5 {
  color: #5b5d5d;
}

.text-newskin > i,
.form-group label > i,
h1 > i,
h2 > i,
h3 > i,
h4 > i,
h5 > i {
  color: #ccc;
  -webkit-text-fill-color: #ccc;
}

.text-position-title {
  color: #4fc2d0;
}

@media (max-width: 576px) {
  .form-signin {
    padding: 0 20px 50px;
  }

  .wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}

/* fade in from left*/

@keyframes aparece-izquierda {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

.fadeInfromLeft {
  animation: aparece-izquierda ease-in-out 1s backwards;
}

@keyframes aparece-derecha {
  from {
    transform: translateX(150%);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

.fadeInfromRigth {
  animation: aparece-derecha ease-in-out 1s backwards;
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.appear {
  animation: appear ease-in-out 2s;
  opacity: 1;
}

.appearLater {
  animation: appear ease-in-out 3s;
  opacity: 1;
}

td.rdtDay,
td.rdtMonth,
td.rdtYear {
  background: rgb(221, 221, 221);
  color: rgb(91, 105, 112);
}

/* @media (max-width: 715px) {
  h3 {
    color: gray !important;

    font-weight: bolder !important;
    font-size: 1.8rem !important;
  }

  h4 {
    color: rgb(13, 90, 114) !important;
    font-weight: 400;
  }

  h1 i {
    font-size: 1.5rem;
  }
  .adminName {
    font-size: 1.3rem;
    line-height: 6px;
  }
} */

.btn-vpsy-gradient {
  gap: 9px;
  border-radius: 50px;
  background-image: linear-gradient(to bottom, #22654e 0%, #0f402f 100%);
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: white !important;
}

.btn-vpsy-solid {
  gap: 9px;
  border-radius: 50px;
  background-image: linear-gradient(to right, #3c7674 0%, #22654e 100%);
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: white !important;
}

.btn-vpsy-outline {
  gap: 9px;
  border-radius: 50px;
  border-style: solid;
  border-width: 1px;
  border-color: #22654e;
  font-weight:400;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #22654e;
  background-color: #fff;
}

.btn-vpsy-outline button {
  background: unset;
}

.btn-vpsy-outline:hover {
  color: #fff;
  background-color: #22654e !important;
}

.vpsy-card {
  border-radius: 16px;
  box-shadow: 0 4px 30px 0 rgba(104, 104, 104, 0.1);
  background-color: #fff;
}

/* Tabs styles */
a.nav-link {
  text-decoration: none;
}

a.nav-link:hover {
  color: #22654e;
}

a.active {
  color: #22654e !important;
}

a.nav-font {
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #5b5d5d;
}

.swal-cancel-btn {
  border-style: solid !important;
  border-width: 1px !important;
  border-color: #22654e !important;
  font-weight:400;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #22654e !important; 
  background-color: #fff !important;
}