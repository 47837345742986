.fill-height {
  min-height: 90vh;
}

table .td,
th {
  vertical-align: middle;
}

.button-patient {
  color: #FFF;
  background-color: #4FB04F;
  min-width: 60px;
  -webkit-box-shadow: 2px 3px 10px #999;
  -moz-box-shadow: 2px 3px 10px #999;
  /* font-size: 1.2rem; */
  border-radius: 8px;
  /* width: 12rem;
  height: 40px; */
}

.button-patient:hover {
  color: #FFF;
}

.button-patient i {
  margin: 4px;
}

.button-patient:hover:enabled i {
  transition: 350ms;
  -webkit-transition: 350ms;
  -moz-transition: 350mss;
  -ms-transition: 350ms;
  -o-transition: 350ms;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
  font-size: 18px;
}

.button-patient:hover:enabled {
  color: #FFF;
  -webkit-transition: background-color300ms ease-out;
  -moz-transition: background-color300ms ease-out;
  -o-transition: background-color300ms ease-out;
  transition: background-color300ms ease-out;
  -webkit-box-shadow: inset -31px -32px 20px -28px rgba(63, 63, 63, 0.16),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
  box-shadow: inset -31px -32px 20px -28px rgba(63, 63, 63, 0.16),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
}

.button-filter:hover:enabled {
  color: #FFF;
  -webkit-transition: background-color300ms ease-out;
  -moz-transition: background-color300ms ease-out;
  -o-transition: background-color300ms ease-out;
  transition: background-color300ms ease-out;
  -webkit-box-shadow: inset -31px -32px 24px -25px rgba(63, 63, 63, 0.12),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
  box-shadow: inset -31px -32px 24px -25px rgba(63, 63, 63, 0.12),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
}

.button-filter {
  border-radius: 10px;
  color:  #FFF;
  background-color: #2097F3;
  -webkit-box-shadow: 2px 3px 10px #999;
  -moz-box-shadow: 2px 3px 10px #999;
}

.button-filter:hover:enabled i {
  transition: 350ms;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.button-patient:hover:enabled {
  color: #FFF;
  -webkit-transition: background-color300ms ease-out;
  -moz-transition: background-color300ms ease-out;
  -o-transition: background-color300ms ease-out;
  transition: background-color300ms ease-out;
  -webkit-box-shadow: inset -31px -32px 20px -28px rgba(63, 63, 63, 0.16),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
  box-shadow: inset -31px -32px 20px -28px rgba(63, 63, 63, 0.16),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
}

.button-filter-rst {
  border-radius: 10px;
  color:  #FFF;
  background-color: #FFA000;
  -webkit-box-shadow: 2px 3px 10px #999;
  -moz-box-shadow: 2px 3px 10px #999;
}

.button-filter-rst:hover:enabled {
  color: #FFF;
  -webkit-transition: background-color300ms ease-out;
  -moz-transition: background-color300ms ease-out;
  -o-transition: background-color300ms ease-out;
  transition: background-color300ms ease-out;
  -webkit-box-shadow: inset -31px -32px 24px -25px rgba(63, 63, 63, 0.12),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
  box-shadow: inset -31px -32px 24px -25px rgba(63, 63, 63, 0.12),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
}

.button-filter-rst:hover:enabled i {
  transition: 350ms;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.button-submit {
  background-color: rgb(65, 163, 224);
  min-width: 150px;
  margin-bottom: 2rem;
  color: white;
  font-size: 1.2rem;
  -webkit-box-shadow: 2px 3px 10px #999;
  -moz-box-shadow: 2px 3px 10px #999;
}

.button-submit:hover:enabled i {
  transition: 350ms;
  -webkit-transition: 350ms;
  -moz-transition: 350ms;
  -ms-transition: 350ms;
  -o-transition: 350ms;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.button-submit:hover:enabled {
  color: wheat;
  background-color: rgba(4, 53, 73, 0.89);
  -webkit-transition: background-color300ms ease-out;
  -moz-transition: background-color300ms ease-out;
  -o-transition: background-color300ms ease-out;
  transition: background-color300ms ease-out;
}

.icon-hover:hover:enabled {
  transition: 350ms;
  -webkit-transition: 350ms;
  -moz-transition: 350ms;
  -ms-transition: 350ms;
  -o-transition: 350ms;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.table-patient {
  border-collapse: collapse;
  border-radius: 22px;
  overflow: hidden;
}

.tablePatient {
  background-color: #F4FCFF;
  color: rgb(56, 56, 56);
  font-size: 1.1rem;
}
.tablePatient td {
  color: rgb(134, 134, 134);
  font-size: 14px;
  padding: 0 10px;
  vertical-align: middle;
}

.headPatient tr th {
  font-family: Poppins;
  font-weight: 400;
  background-image: linear-gradient(to bottom, #3c7674 0%, #22654e 100%);
  padding: 5px 20px;
  color: white;
  height: 40px;
  vertical-align: middle;
}

.td-patient-name {
  width: 151px;
  height: 18px;
  margin: 1px 40px 3px 73px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5b5d5d;
}

.td-patient-name:hover {
  cursor: pointer;
}

.patient-title {
  font-family: Poppins;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  text-align: left;
  color: #517473;
}

.patient-title-arrow {
  position: relative;
  right: 0.6rem;
  color: #517473;
}

.patient-label {
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5b5d5d;
}

.patient-input {
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5b5d5d;
}

.patient-input-phone {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}

.patient-input-description {
  height: 182px !important;
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5b5d5d;
}

.patient-form-card {
  background-color: white;
  border-radius: 16px;
}

.icon-red {
  color: #aa1313;
}

.no-results {
  font-family: Poppins;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  text-align: left;
  color: #517473;
}

.no-results a {
  color: #6ca6a4;
  font-size: 0.88rem;
  font-family: Poppins;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5;
  letter-spacing: 1.08px;
  text-decoration: underline;
}

.td-exam-status {
  width: 151px;
  height: 18px;
  margin: 1px 40px 3px 73px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5b5d5d;
}

.td-exam {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.td-exam:hover {
  cursor: pointer;
}