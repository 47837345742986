.fill-height {
  min-height: 90vh;
}

.icon-hover:hover:enabled {
  transition: 350ms;
  -webkit-transition: 350ms;
  -moz-transition: 350ms;
  -ms-transition: 350ms;
  -o-transition: 350ms;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.button-exam {
  color: #FFF;
  background-color: #4FB04F;
  min-width: 60px;
  -webkit-box-shadow: 2px 3px 10px #999;
  -moz-box-shadow: 2px 3px 10px #999;

  border-radius: 8px;
  /* width: 10rem; */
  height: 40px;
}

.button-exam i {
  margin: 4px;
}

.button-exam:hover:enabled i {
  transition: 350ms;
  -webkit-transition: 350ms;
  -moz-transition: 350ms;
  -ms-transition: 350ms;
  -o-transition: 350ms;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.button-exam:hover:enabled {
  color: #FFF;
  -webkit-transition: background-color300ms ease-out;
  -moz-transition: background-color300ms ease-out;
  -o-transition: background-color300ms ease-out;
  transition: background-color300ms ease-out;
  -webkit-box-shadow: inset -31px -32px 20px -28px rgba(63, 63, 63, 0.16),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
  box-shadow: inset -31px -32px 20px -28px rgba(63, 63, 63, 0.16),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
}

.button-deleteCategory {
  padding-bottom: 0.6rem;
  border-radius: 10px;
  color: rgb(105, 105, 105);
  background-color: #fff;
  font-weight: bold;
}

.button-deleteCategory:hover:enabled {
  -webkit-transition: background-color300ms ease-out;
  -moz-transition: background-color300ms ease-out;
  -o-transition: background-color300ms ease-out;
  transition: background-color300ms ease-out;
  color: red;
  background-color: rgb(221, 221, 221);
}

.button-deleteExam:hover:enabled {
  color: rgb(71, 71, 71);
  -webkit-transition: background-color300ms ease-out;
  -moz-transition: background-color300ms ease-out;
  -o-transition: background-color300ms ease-out;
  transition: background-color300ms ease-out;
  -webkit-box-shadow: inset -31px -32px 24px -25px rgba(63, 63, 63, 0.12),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
  box-shadow: inset -31px -32px 24px -25px rgba(63, 63, 63, 0.12),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
  color: red;
  background-color: rgb(221, 221, 221);
}

.button-deleteExam {
  border-radius: 10px;
  color: #aa2b2b;
  background-color: #fff;
  font-weight: bold;
}
.button-deleteExam:hover:enabled i {
  transition: 350ms;
}

.table-fixed {
  table-layout: fixed;
}

.tableExams {
  background-color: rgba(255, 255, 255, 0.9);
}

.tableExams td{
text-align: center;
}

.headExams {
  height: 60px;
}

.headExams label {
  color: rgb(56, 56, 56);
  font-size: 0.7rem;
}

.zScoreCellGray {
  color: #606060;
  border: 0.2px solid rgb(192, 190, 190);
}

.zScoreCellGreen {
  color: #1fac26;
  border: 0.2px solid rgb(192, 190, 190);
}

.zScoreCellYellow {
	color: #c7cb20;
  border: 0.2px solid rgb(192, 190, 190);
}

.zScoreCellRed {
	color: #aa1f1f;
  border: 0.2px solid rgb(192, 190, 190);
}

.headExams td {
  border: solid 0.8px rgba(124, 174, 173, 0.24);
  color: rgb(122, 122, 122);
}

.headExams th {
  color: #5b5d5d;
  font-size: 12px;
  padding: 1px;
  text-align: center;
  background-color: #fff;
  border: solid 0.8px rgba(124, 174, 173, 0.24);
  font-family: Poppins;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
}
.headExams th hr {
  width: 25px;
  margin-top: 5px;
  margin-bottom: 5px;
}

/* COLUMNS WIDTH */
.headExams tr .col-test-type {
  min-width: 10.5rem;
  width: 13.1rem;
}

.tableExams td {
  text-align: center;
  background-color: #f7f7f7;
}

tr .col-test-name {
  font-family: Poppins;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.76;
  letter-spacing: normal;
  text-align: center;
  color: #5b5d5d;
}


@media (max-width: 715px) {
  .button-deleteExam {
    width: 100%;
    margin-bottom: 10px;
  }
}
.tableExams .form-control:disabled, .tableExams .form-control[readonly], .tableExams.form-control  {
  text-align: center;
  margin-left: 2px;
  margin-right: 2px;
}


.btn-add-stdy {
  color: #696969;
  background-color: #FFF;
  min-width: 150px;
  -webkit-box-shadow: 2px 3px 10px #999;
  -moz-box-shadow: 2px 3px 10px #999;

  border-radius: 8px;
  width: 10rem;
  height: 40px;
}

.btn-add-stdy:hover:enabled {
  color: #000;
  -webkit-transition: background-color300ms ease-out;
  -moz-transition: background-color300ms ease-out;
  -o-transition: background-color300ms ease-out;
  transition: background-color300ms ease-out;
  -webkit-box-shadow: inset -31px -32px 20px -28px rgba(63, 63, 63, 0.16),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
  box-shadow: inset -31px -32px 20px -28px rgba(63, 63, 63, 0.16),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
}

.btn-add-stdy-arrow {
  color: #FFF;
  background-color: #4FB04F;
  max-width: 4rem;
  min-width: 1.5rem;
  -webkit-box-shadow: 2px 3px 10px #999;
  -moz-box-shadow: 2px 3px 10px #999;
  border-radius: 8px;
  height: 40px;
}

.btn-add-stdy-arrow:hover:enabled {
  color: #FFF;
  -webkit-transition: background-color300ms ease-out;
  -moz-transition: background-color300ms ease-out;
  -o-transition: background-color300ms ease-out;
  transition: background-color300ms ease-out;
  -webkit-box-shadow: inset -31px -32px 20px -28px rgba(63, 63, 63, 0.16),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
  box-shadow: inset -31px -32px 20px -28px rgba(63, 63, 63, 0.16),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
}

.btn-add-stdy i {
  margin: 4px;
}

.btn-add-stdy:hover:enabled i {
  transition: 350ms;
  -webkit-transition: 350ms;
  -moz-transition: 350ms;
  -ms-transition: 350ms;
  -o-transition: 350ms;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.btn-dl-pdf {
  color: rgb(105, 105, 105);
  background-color: rgb(255, 255, 255);
  min-width: 190px;
  border-radius: 19px;
  height: 38px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.btn-dl-pdf:hover:enabled {
  color: rgb(71, 71, 71);
  -webkit-transition: background-color300ms ease-out;
  -moz-transition: background-color300ms ease-out;
  -o-transition: background-color300ms ease-out;
  transition: background-color300ms ease-out;
  -webkit-box-shadow: inset -31px -32px 20px -28px rgba(63, 63, 63, 0.16),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
  box-shadow: inset -31px -32px 20px -28px rgba(63, 63, 63, 0.16),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
}

.btn-dl-pdf i {
  margin: 4px;
}

.animated-glow {
  animation: glow 750ms infinite alternate;
}

@keyframes glow {
  from {
    box-shadow: 0 0 5px -5px #FFA000;
  }
  to {
    box-shadow: 0 0 5px 5px #FFA000;
  }
}

/* Removing arrows from number input */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .exam-title {
    font-family: Poppins;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: left;
    color: #517473;
  }

  .exam-card {
    overflow: hidden;
    border-radius: 16px;
    background-color: #fcfcfc;
    min-height: 83px;
  }

  .exam-card .exam-card-topbar {
      height: 19px;
      background-image: linear-gradient(to bottom, #3c7674 0%, #22654e 100%);
  }

  .exam-card-section {
    background-color: #fff;
  }

  .new-exam-input {
    width: -webkit-fill-available;
    height: 45px;
    border: solid 0.8px rgba(124, 174, 173, 0.24);
    flex-grow: 0;
    margin: 0px 0.1px 0 0;
    font-family: Poppins;
    font-size: 12.4px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #5b5d5d;
  }

.category-label {
  font-family: Poppins;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  align-self: center;
  color: #5b5d5d;
}

.test-tables {
  max-height: 350px;
  overflow-y: scroll;
}

.test-select-btn {
  width: 150px;
  height: 75px;
  flex-grow: 0;
  border-radius: 11.7px;
  background-color: rgba(124, 174, 173, 0.1);
}

.test-select-btn:hover {
  border: solid 0.7px #22654e;
}

.test-select-btn-txt {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #5b5d5d;
 }

  .test-select-icon {
    font-size: 30px;
  }

  .exam-content-min-height {
    min-height: 400px;
  }

  @media screen and (max-width: 770px) {
    .md-hide {
      display: none;
    }
    .md-full-width {
      max-width: 100%;
    }
  }

  .watch-video-banner {
    height: 121px;
    flex-grow: 0;
    border-radius: 16px;
    box-shadow: 0 4px 15px 0 rgba(104, 104, 104, 0.1);
    background-color: transparent;
    border-style: solid;
    border-width: 2px;
    border-color: #22654e;
    animation: glow 750ms infinite alternate;
  }
  
  .watch-video-banner-icon {
    color: #22654e;
    font-size: 3.9rem;
  }
  
  .watch-video-banner-text {
    font-size: 1.4rem;
    font-family: Poppins;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: 1.08px;
    color: #22654e
  }
  
  .watch-video-banner-link {
    color: #22654e;
    font-size: 0.88rem;
    font-family: Poppins;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: 1.08px;
    text-decoration: underline;
  }
  .watch-video-banner-link:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 1200px) {
    .watch-video-banner-icon {
      display: none;
    }
    .watch-video-banner-text {
      font-size: 0.8rem;
    }
  }
  
  @media screen and (max-width: 770px) {
    .watch-video-banner {
      display: none;
    }
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 5px -5px #72B59e;
    }
    to {
      box-shadow: 0 0 5px 5px #72B59e;
    }
  }
  

  .flip-card {
    height: 121px;
    background-color: transparent;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateX(180deg);
  }

  .flip-card .flip-card-inner {
    animation: mymove 8s infinite alternate;
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
  }
  
  .flip-card-back {
    transform: rotateX(180deg);
  }

  @keyframes mymove {
    0%   {transform: rotateX(0deg);}
    45%  {transform: rotateX(0deg);}
    55%  {transform: rotateX(180deg);}
    100% {transform: rotateX(180deg);}
  }