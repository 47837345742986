.button-submit {
  background-color: rgb(65, 163, 224);
  min-width: 150px;
  margin-bottom: 2rem;
  color: white;
  font-size: 1.2rem;
}

.button-submit:hover:enabled i {
  transition: 350ms;
  -webkit-transition: 350ms;
  -moz-transition: 350ms;
  -ms-transition: 350ms;
  -o-transition: 350ms;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.button-submit:hover:enabled {
  color: wheat;
  background-color: rgba(4, 53, 73, 0.89);
  -webkit-transition: background-color300ms ease-out;
  -moz-transition: background-color300ms ease-out;
  -o-transition: background-color300ms ease-out;
  transition: background-color300ms ease-out;
}

h1 span {
  text-decoration: none;
  font-size: 1.9rem;
  font-family: 'Source Sans Pro', sans-serif;
  color: rgb(48, 56, 56);
}

.userForm {
  margin-top: 1rem;
  margin-bottom: 1rem;  
  border-radius: 16px;
  box-shadow: 0 4px 30px 0 rgba(104, 104, 104, 0.1);
  background-color: #fff;
}

.title {
  font-family: 'Source Sans Pro', sans-serif;
  /* font-size: 1.2rem; */
  margin: 0.5rem;
}

.subtitle-section,
.user-title-container {
  margin-bottom: 10px;
  display: flex;
}
.user-title-container h1,
.user-title-container h2,
.user-title-container h3,
.user-title-container h4,
.user-title-container h5 {
  margin: 0;
}
.text-position-title {
  color: #4fc2d0;
}
.user-buttons {
  display: flex;
  justify-content: flex-end;
  flex: 2;
}

.edit-profile-btn {
  width: 95%;
  background-color: transparent;
  color: #2097F3;
  border-color: #2097F3;
}

.edit-profile-btn:hover {
  color: #fff;
  background-color: #2097F3;
  border-color: #2097F3;
}

.changepass-btn {
  width: 95%;
  background-color: transparent;
  color: #FFA000;
  border-color: #FFA000;
}

.changepass-btn:hover {
  color: #fff;
  background-color: #FFA000;
  border-color: #FFA000;
}

.save-btn {
  background-color: transparent;
  color: #4FB04F;
  border-color: #4FB04F;
}

.save-btn:hover {
  color: #fff;
  background-color: #4FB04F;
  border-color: #4FB04F;
}

.edit-pass-label {
  text-decoration: underline;
}

.edit-pass-label:hover {
  cursor: pointer;
}