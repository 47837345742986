/* Calendar component styles */

/* Calendar Border*/
.rs-calendar-table {
    background-color: white;
    border-collapse: separate;
    border-spacing: 3px 3px;
}

div .rs-calendar-panel .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    background-color: transparent;
    color: inherit;
    border: none;
    padding: 4px;
}

.rs-calendar {
    border-radius: 16px;
}

/* Title month year  */
.rs-calendar-panel .rs-calendar-header-title {
    width: 145px;
    margin: 0 15px 0 14px;
    font-family: Poppins;
    font-size: 22px !important;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18 !important;
    letter-spacing: normal;
    color: #517473;
}

/* DAYS TITLE ROW */
.rs-calendar-table-header-row {
    height: 31px !important;
}

/* DAYS TITLE CELL */
.rs-calendar-panel .rs-calendar-table-header-row .rs-calendar-table-cell {
    border: 0;
}

.rs-calendar-panel .rs-calendar-table-header-row .rs-calendar-table-cell-content {
    height: 31px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10.5px 0;
    border-radius: 3.5px;
    background-image: linear-gradient(to bottom, #3c7674 0%, #22654e 100%);
}

/* DAYS TITLE TEXT */
.rs-calendar-table-header-row .rs-calendar-table-cell-content {
    height: 25px;
    flex-grow: 0;
    font-family: Poppins !important;
    font-size: 17.5px !important;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4 !important;
    letter-spacing: normal;
    text-align: left;
    color: #fefff4 !important;
}

.rs-calendar-table-header-row .rs-calendar-table-cell-content:hover {
    pointer-events: none !important;
}

.rs-calendar-table-cell-content {
    cursor: default !important;
    height: auto !important; 
}

.rs-calendar-table-cell-content:hover {
    background-color: transparent !important;
}

.rs-calendar-table-header-row {
    pointer-events: none;
}

/* day cells row */
.rs-calendar-table-row {
    height: 145px;
}

/* Single day cell */
.rs-calendar-panel .rs-calendar-table-cell {
    height: 100%;
    margin: 1px;
    gap: 12.2px;
    border-radius: 3.5px;
    border: solid 1px #bbb;
}

/* day cell number */
.rs-calendar-panel .rs-calendar-table-cell-day {
    width: 22px;
    height: 25px;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 17.5px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    padding-left: 0.88rem;
}

/* Today number */
.rs-calendar-panel .rs-calendar-table-cell-is-today .rs-calendar-table-cell-day {
    color: #22654e !important;
    background-color: unset !important;
    width: 22px !important;
    height: 25px !important;
    margin-left: 0 !important;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 17.5px;
    font-weight: 600;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    padding-left: 0.88rem;
    text-decoration: underline solid #42856e 4px;
}

/* PAST MONTH CELLS */
.rs-calendar-table-cell-un-same-month {
    border: 0 !important;
    background-color: rgba(124, 174, 173, 0.1);
}

/* Appointment item */
.appointments-container {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.appointment-item {
    margin-top: 3px;
    height: 23px;
    width: 100%;
    gap: 3.5px;
    padding: 0 2px;
    border-radius: 22px;
    font-size: 12px;
    word-wrap: nowrap;
}

.appointment-item a {
    vertical-align: -webkit-baseline-middle;
}


.appointment-item-online {
    color: #fff;
    background-color: #22654e;
    border-width: 1px;
    border-style: solid;
    border-color: #22654e;
    transition: all 100ms;
}

.appointment-item-in-person {
    color: #22654e;
    background-color: #fff;
    border-width: 1px;
    border-style: solid;
    border-color: #22654e;
    transition: all 100ms;
}

.appointment-item:hover {
    color: #153;
    background-color: #efe;
}

 /* Appointment red dot */
 .rs-badge-independent.rs-badge-dot {
    display: none;
 }