div#login-container {
  max-height: 100%;
  max-width: 100%;
}

div#login-side-img {
  border-radius: 10% 0 0 10%;
  height: 100vh;
  width: 100vh;
  border: 2px green;
}

div#login-side-img div {
  float: left;
  top: 0px;
  z-index: 1000;
  background-color: rgba(34,101,78,0.5);
  border-radius: 10% 0 0 10%;
  min-height: 541px;
  height: 100%;
  width: 110%;
  position: absolute;
}

div#login-side-img img {
  border-radius: 10% 0 0 10%;
  object-fit: cover;
  min-height: 541px;
  height: 100%;
  width: 110%;
  position: relative;
  background-size: cover;
  background-position: 50% 50%;
  background-image: url();
}

.btn-login-font {
  text-wrap: nowrap;
  font-size: 15x;
  line-height: 2.2;
}

.btn-recover-pass {
  width: 152px;
  height: 21px;
  margin: 30px 57px 40px 18px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #517473;
  text-decoration: underline;
}

.login-alert {
  border-radius: 16px;
  border: solid 1px #f2b3ae;
  background-color: #ffebed;
}

.login-alert-text {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #bc0021;
}


@media screen and (max-width: 770px) {
  div#login-side-img {
    display: none;
  }
}