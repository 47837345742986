card {
  width: 18rem;
}

.margin-left {
  margin-left: 5rem;
}

@media (max-width: 420px){
  .category{
    margin-left: 0 !important;
  }
  .category-hidden{
    display: none;
  }
  .provider{
    margin: 0 !important;
    display: inline-block !important;
  }
  .provider-name{
    margin-right: 0 !important;
  }
  .postal-code{
    margin-top: 1rem !important;
  }
}

@media (min-width: 1025px ){

  .category-hidden-lg{
    display: none;
  }
}

.margin-right {
  margin-right: 11.5rem;
}

.margin {
  margin-left: 1rem;
}

.margin-top {
  margin-top: 1rem;
}
.overlay {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  animation-duration: 0.5s;
}
.fold-background {
  background-color: #d6eaf8;
}

h1 {
  margin: 2rem 0 1rem;
  color: #4fc2d0;
  margin-top: 10px;
}

.jumbotron {
  background-size: cover;
  margin-top: 20px;
  text-align: center;
}

.table {
  border-collapse: collapse;
}

.jumbotron span {
  color: #36b9cc !important;
}

.clickable {
  cursor: pointer;
}

.clickable:hover:enabled {
  color: #36b9cc;
}

@media (max-width: 576px) {
  .jumbotron {
    padding-bottom: 50px;
  }
}

.creation-info {
  border-radius: 1rem;
  border: solid #858796 2px;
}

.horizontal-scrollable {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  min-width: 1060px;
}