table .td,
th {
  vertical-align: middle;
}

.button-appointment {
  color: white;
  flex-grow: 0;
  border-radius: 19px;
  box-shadow: 30px 70px 120px 0 rgba(27, 49, 66, 0.13);
  border: solid 1px rgba(243, 243, 243, 0);
  background-image: linear-gradient(to right, #3c7674 0%, #22654e 100%);
  font-family: Poppins;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /* Overlaying over calendar */
  z-index: 1;
  text-align: left;    
  position: relative;
  top: 3.5rem;
}

.button-appointment i {
  margin: 4px;
}

.button-appointment:hover:enabled {
  color: rgb(255, 255, 255);
  /* background-color: rgb(130, 140, 255); */
  -webkit-box-shadow: 2px 3px 10px rgb(0, 0, 0);
  -webkit-transition: background-color300ms ease-out;
  -moz-transition: background-color300ms ease-out;
  -o-transition: background-color300ms ease-out;
  transition: background-color300ms ease-out;
  -webkit-box-shadow: inset -31px -32px 20px -28px rgba(63, 63, 63, 0.16),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
  box-shadow: inset -31px -32px 20px -28px rgba(63, 63, 63, 0.16),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
}

.add-patient-banner {
  height: 121px;
  flex-grow: 0;
  border-radius: 16px;
  box-shadow: 0 4px 15px 0 rgba(104, 104, 104, 0.1);
  background-image: linear-gradient(to bottom, #3c7674 0%, #22654e 100%);
}

.add-patient-banner-icon {
  color: white;
  font-size: 3.9rem;
}

.add-patient-banner-text {
  font-size: 1.4rem;
  font-family: Poppins;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5;
  letter-spacing: 1.08px;
}

.add-patient-banner-link {
  color: rgb(191, 238, 176);
  font-size: 0.88rem;
  font-family: Poppins;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5;
  letter-spacing: 1.08px;
  text-decoration: underline;
}

.appointment-popover {
  min-width: 250px;
  max-width: 350px;
}

.appointment-popover-button {
  font-size: 1.4rem;  
  color: #22654e;
}

.appointment-popover-button:hover {
  font-size: 1.4rem;
  color: #858796;
}

.appointment-popover-button-delete {
  font-size: 1.4rem;  
  color: #22654e;
}

.appointment-popover-button-delete:hover {
  font-size: 1.4rem;
  color: #b11010;
}

.appointment-popover-icons {
  font-size: 1.14rem;
  color: #22654e;
}

.appointment-popover-name {
  text-shadow: 0 4px 30px rgba(104, 104, 104, 0.1);
  font-family: Poppins;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #22654e;
}

.appointment-popover-item {
  text-shadow: 0 4px 30px rgba(104, 104, 104, 0.1);
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #5b5d5d;
}

.appointment-alert {
  border-radius: 30px;
  border: solid 1px #f2b3ae;
  background-color: #ffebed;
}

.appointment-alert-text {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #bc0021;
}

@media screen and (max-width: 1200px) {
  .add-patient-banner-icon {
    display: none;
  }
  .add-patient-banner-text {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 770px) {
  .add-patient-banner {
    display: none;
  }
}