.pfp-input {
    display: none;
}

.pfp-img {
    cursor: pointer;
    object-fit: cover;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    background: #444;
}

.pfp-img:hover {
    filter: brightness(75%);
}

.no-hover {
    pointer-events: none;
}

.pfp-card {
    cursor: pointer;
    object-fit: cover;
    border-radius: 50%;
    height: 80px;
    width: 80px;
}

.pfp-card:hover {
    filter: brightness(75%);
}