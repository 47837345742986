.login-sign {
  color: #fff;
  background-color: #2097f3;
  -webkit-box-shadow: 2px 3px 10px rgb(0, 64, 255);
}

.login-sign:hover {
  color: #fff;
  background-color: #0077e3;
  -webkit-transition: background-color300ms ease-out;
  -moz-transition: background-color300ms ease-out;
  -o-transition: background-color300ms ease-out;
  transition: background-color300ms ease-out;
  -webkit-box-shadow: inset -31px -32px 20px -28px rgba(63, 63, 63, 0.16),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
  box-shadow: inset -31px -32px 20px -28px rgba(63, 63, 63, 0.16),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
}

.btn-sign-up {
  color: #fff;
  background-color: #4fb04f;
  min-width: 60px;
  -webkit-box-shadow: 2px 3px 10px #999;
  -moz-box-shadow: 2px 3px 10px #999;
  border-radius: 8px;
  width: 10rem;
  height: 40px;
}

.btn-sign-up i {
  margin: 4px;
}

.btn-sign-up:hover:enabled i {
  transition: 350ms;
  -webkit-transition: 350ms;
  -moz-transition: 350ms;
  -ms-transition: 350ms;
  -o-transition: 350ms;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.btn-sign-up:hover:enabled {
  color: #fff;
  -webkit-transition: background-color300ms ease-out;
  -moz-transition: background-color300ms ease-out;
  -o-transition: background-color300ms ease-out;
  transition: background-color300ms ease-out;
  -webkit-box-shadow: inset -31px -32px 20px -28px rgba(63, 63, 63, 0.16),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
  box-shadow: inset -31px -32px 20px -28px rgba(63, 63, 63, 0.16),
    inset 50px 50px 23px -30px rgba(63, 63, 63, 0.12);
}

.signup-font{
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 3.52px;
  text-align: center;
}

.signup-card {
  padding: 40px 40px 30px 30px;
  border-radius: 16px;
  box-shadow: 0 4px 15px 0 rgba(104, 104, 104, 0.1);
  background-color: #fff;
}

.signup-card-title {
  white-space: nowrap;
  font-family: Poppins;
  font-size: 22px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #5b5d5d;
}
